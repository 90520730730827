import React, { Dispatch, SetStateAction } from "react";

import { useCreateInput } from "../../hooks/useCreateInput";
import Fieldset from "../ui/Fieldset/Fieldset";
import { IInputField } from "../ui/Input/Input";
import { EJobApplicationSkillsInputs } from "./useJobApplicationSkillsInputs";

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
}

const JobApplicationSkills: React.FC<IProps> = ({ inputs, setInputs }) => {
	const createInput = useCreateInput(inputs, setInputs);

	return (
		<Fieldset label="Taidot" transparent>
			{createInput(EJobApplicationSkillsInputs.educationText)}
			{createInput(EJobApplicationSkillsInputs.workExperienceText)}
			{createInput(EJobApplicationSkillsInputs.attachmentCV)}
		</Fieldset>
	);
};

export default React.memo(JobApplicationSkills);

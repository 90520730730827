import React from "react";

import { IAttribute } from "../../interfaces/IAttribute";
import Heading from "../Heading/Heading";
import Button, { EButtonSize } from "../ui/Button/Button";
import AttributesAttachList from "./AttributesAttachList";
import { useAttributeSelectModal } from "./useAttributeSelectModal";
import { ECategoryType } from "../../interfaces/ICategory";

interface IProps {
	attributes: IAttribute[];
	onAdd: (attribute: IAttribute) => void;
	onDelete: (id: string) => void;
	onEdit: (attribute: IAttribute) => void;
	showTitle?: boolean;
}

const AttributesAttach: React.FC<IProps> = ({ attributes, onAdd, onDelete, onEdit, showTitle }) => {
	const openAttributeSelectModal = useAttributeSelectModal();

	const addAttributeHandler = async () => {
		const attr = await openAttributeSelectModal(attributes, "Lisää ominaisuus", [ECategoryType.INTERPRETATION_SEARCH, ECategoryType.INTERPRETATION_SUBTYPE]);
		if (attr) {
			onAdd(attr);
		}
	};

	return (
		<>
			{showTitle && <Heading tag="h3">Ominaisuudet</Heading>}
			<div style={{ marginBottom: "1rem" }}>
				<Button onClick={addAttributeHandler} size={EButtonSize.SMALL}>Lisää ominaisuus</Button>
				<AttributesAttachList attributes={attributes} onEdit={onEdit} onDelete={onDelete} />
			</div>
		</>
	);
};

export default AttributesAttach;

import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";


export enum EJobApplicationSkillsInputs {
	educationText = "educationText",
	workExperienceText = "workExperienceText",
	attachmentCV = "attachmentCV",

}

export const useJobApplicationSkillsInputs = () => {
	const [skillsInputs, setSkillsInputs] = useState<IInputField>({
		[EJobApplicationSkillsInputs.educationText]: {
			type: EInputType.textarea,
			label: "Koulutuksen kuvaus",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSkillsInputs.workExperienceText]: {
			type: EInputType.textarea,
			label: "Työkokemuksen kuvaus",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSkillsInputs.attachmentCV]: {
			type: EInputType.dropzone,
			label: "CV",
			multiple: true,
			value: [],
		},
	});
	return { skillsInputs, setSkillsInputs };
}
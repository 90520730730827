import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import InputGroup from '../ui/InputGroup/InputGroup';
import { EJobApplicationBasicInput } from './useJobApplicationBasicInputs';


interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>
}


const JobApplicationBasic: React.FC<IProps> = ({ inputs, setInputs }) => {
	const createInput = useCreateInput(inputs, setInputs);
	return (
		<Fieldset label="Perustiedot" transparent>
			<InputGroup>
				{createInput(EJobApplicationBasicInput.firstName)}
				{createInput(EJobApplicationBasicInput.lastName)}
			</InputGroup>
			<InputGroup>
				{createInput(EJobApplicationBasicInput.email)}
				{createInput(EJobApplicationBasicInput.phoneNumber)}
			</InputGroup>
			{createInput(EJobApplicationBasicInput.gender)}
			<InputGroup>
				{createInput(EJobApplicationBasicInput.address)}
				{createInput(EJobApplicationBasicInput.zip)}
				{createInput(EJobApplicationBasicInput.city)}
			</InputGroup>
		</Fieldset>
	)
}




export default React.memo(JobApplicationBasic);
import React from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import classes from "./EezyTrusterEvent.module.scss";
import EezyTrusterEventStatus from '../EezyStatusBadge/EezyTrusterEventStatus';
import EezyTrusterPaymentActions from './EezyTrusterPaymentActions';

interface IProps {
	payment: IEezyTrusterPayment;
	index: number;
	type: IEezyTrusterEvent["type"];
}

const EezyTrusterPaymentHeader: React.FC<IProps> = ({ payment, index, type }) => {
	return (
		<div className={classes.paymentHeader}>
			<h2><a href={`/users/edit/${payment.user.id}`} target="_blank" rel="noopener noreferrer">{payment.user.firstName} {payment.user.lastName}</a></h2>
			<span><EezyTrusterEventStatus status={payment.status} tooltip={payment.status === "FAILED" ? payment.statusMessage : undefined} /></span>
			<span>{payment.eezySalesOrderId}</span>
			<EezyTrusterPaymentActions type={type} index={index} payment={payment} />
		</div>
	);
};

export default EezyTrusterPaymentHeader;

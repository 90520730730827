import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../../../hooks/useCreateInput';
import { useInitFormOnce } from '../../../../hooks/useInitFormOnce';
import { IDegree } from '../../../../interfaces/IJobApplicationDegree';
import { ETranslation } from '../../../../translations/translation-keys';
import Fieldset from '../../../ui/Fieldset/Fieldset';
import { EInputType, IInputField, IOption } from '../../../ui/Input/Input';
import { getInputData, validateInputs } from '../../../ui/Input/input-utils';

enum EInputs {
	type = "type",
	name = "name",
	description = "description",
	attachments = "attachments",
}

interface IProps {
	onChange: (degree: IDegree, isValid: boolean) => void;
	onRemove: (id: string) => void;
	degree: IDegree;
	number: number;
}

const JobApplicationDegree: React.FC<IProps> = ({ onChange, degree, onRemove, number }) => {

	const id = degree.id;

	const { inputs, setInputs } = useInputs();

	useInitFormOnce(degree, setInputs);

	useEffect(() => {
		const newDegree = getInputData<IDegree>(inputs);
		newDegree.id = id;
		const isValid = validateInputs(inputs);
		onChange(newDegree, isValid);
	}, [inputs, onChange, id]);

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<Fieldset label={`Tutkinto ${number}`} onRemove={() => onRemove(degree?.id)}>
			{createInput(EInputs.type)}
			{createInput(EInputs.name)}
			{createInput(EInputs.attachments)}
			{createInput(EInputs.description)}
		</Fieldset>
	);
};

const useInputs = () => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.type]: {
			type: EInputType.reactSelect,
			label: "Tutkinto",
			value: "",
			options: DEGREE_TYPE_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EInputs.name]: {
			type: EInputType.text,
			label: "Nimi",
			value: "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.attachments]: {
			type: EInputType.dropzone,
			label: "Tutkintotodistus",
			value: [],
			multiple: true,
		},
	});
	return { inputs, setInputs };
}


const DEGREE_TYPE_OPTIONS: IOption[] = [
	{ value: "BACHELOR", label: "Korkeakoulututkinto"},
	{ value: "MASTER", label: "Ylempi korkeakoulututkinto"},
]

export default JobApplicationDegree;

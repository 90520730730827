import React from 'react';
import Button, { EButtonSize } from '../../ui/Button/Button';
import JobApplicationDegree from './JobApplicationDegree/JobApplicationDegree';
import { IDegree } from '../../../interfaces/IJobApplicationDegree';


interface IProps {
	degrees: IDegree[];
	onAdd: VoidFunction;
	onRemove: (id: string) => void;
	onChange: (degree: IDegree, isValid: boolean) => void;
}

const JobApplicationDegrees:React.FC<IProps> = ({ onAdd, degrees, onRemove, onChange }) => {

	return (
		<>
			<Button onClick={onAdd} size={EButtonSize.SMALL}>Lisää uusi</Button>
			{degrees.map((degree, i) => <JobApplicationDegree key={degree.id} degree={degree} onChange={onChange} onRemove={onRemove} number={i + 1} />)}
		</>
	)
}

export default JobApplicationDegrees;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ERoute } from '../../classes/Routes';
import { EUserRole, User } from '../../classes/User';
import JobApplicationAdmin from '../../components/JobApplication/JobApplicationAdmin';
import JobApplicationBasic from '../../components/JobApplication/JobApplicationBasic';
import JobApplicationDegrees from '../../components/JobApplication/JobApplicationDegrees/JosApplicationDegrees';
import { useJobApplicationDegrees } from '../../components/JobApplication/JobApplicationDegrees/useJobApplicationDegrees';
import JobApplicationPaymentInfo from '../../components/JobApplication/JobApplicationPaymentInfo';
import JobApplicationSecurityClearance from '../../components/JobApplication/JobApplicationSecurityClearance';
import JobApplicationSkills from '../../components/JobApplication/JobApplicationSkills';
import JobApplicationToolbar from '../../components/JobApplication/JobApplicationToolbar';
import {
    EJobApplicationAdminInputs,
    useJobApplicationAdminInputs,
} from '../../components/JobApplication/useJobApplicationAdminInputs';
import { useJobApplicationBasicInputs } from '../../components/JobApplication/useJobApplicationBasicInputs';
import { useJobApplicationPaymentInfoInputs } from '../../components/JobApplication/useJobApplicationPaymentInfoInputs';
import {
    useJobApplicationSecurityClearanceInputs,
} from '../../components/JobApplication/useJobApplicationSecurityClearanceInputs';
import { useJobApplicationSkillsInputs } from '../../components/JobApplication/useJobApplicationSkillsInputs';
import Container from '../../components/ui/Container/Container';
import Fieldset from '../../components/ui/Fieldset/Fieldset';
import { getInputData, initForm } from '../../components/ui/Input/input-utils';
import Spinner from '../../components/ui/Spinner/Spinner';
import Tabs, { ITab } from '../../components/ui/Tabs/Tabs';
import UserLanguagePairs from '../../components/User/UserLanguagePairs/UserLanguagePairs';
import { useUserLanguagePairs } from '../../components/User/UserLanguagePairs/useUserLanguagePairs';
import { IJobApplication } from '../../interfaces/IJobApplication';
import IAppState from '../../interfaces/store/IAppState';
import IAuthState from '../../interfaces/store/IAuthState';
import IJobApplicationState from '../../interfaces/store/IJobApplicationState';
import { EJobApplicationStatus } from '../../shared/job-application-data';
import * as actions from '../../store/actions';

enum EJobApplicationPaths {
	basic = "basic",
	skills = "skills",
	admin = "admin",
	full = "full",
}

const createTabs = (user: User): ITab[] => [
	{
		title: "Perustiedot",
		path: EJobApplicationPaths.basic,
		visible: true,
	},
	{
		title: "Taidot",
		path: EJobApplicationPaths.skills,
		visible: true,
	},
	{
		title: "Hallinta",
		path: EJobApplicationPaths.admin,
		visible: user.hasRole(EUserRole.ADMIN),
	},
];

const createPath = (tab: EJobApplicationPaths) => {
	return ERoute.JOB_APPLICATION_EDIT + "/" + tab;
};

interface IMatch {
	id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const JobApplicationEditPage: React.FC<IProps> = ({ match }) => {
	const { basicInputs, setBasicInputs } = useJobApplicationBasicInputs();
	const { paymentInfoInputs, setPaymentInfoInputs } = useJobApplicationPaymentInfoInputs();
	const { skillsInputs, setSkillsInputs } = useJobApplicationSkillsInputs();
	const { adminInputs, setAdminInputs } = useJobApplicationAdminInputs();
	const { securityClearanceInputs, setSecurityClearanceInputs } = useJobApplicationSecurityClearanceInputs();
	const {
		setUserLanguagePairs,
		userLanguagePairAddHandler,
		userLanguagePairChangeHandler,
		userLanguagePairRemoveHandler,
		userLanguagePairs,
	} = useUserLanguagePairs();
	const { degreeAddHandler, degreeChangeHandler, degreeRemoveHandler, degrees, setDegrees } =
		useJobApplicationDegrees();
	const dispatch = useDispatch();

	const { jobApplication, loading } = useSelector<IAppState, IJobApplicationState>((state) => state.jobApplication);

	const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);

	const tabs = createTabs(user!);

	const id = match.params.id;

	useEffect(() => {
		if (id !== "add") {
			dispatch(actions.getJobApplication(id));
		}
		return () => {
			dispatch(actions.getJobApplicationClear());
		};
	}, [dispatch, id]);

	useEffect(() => {
		if (jobApplication) {
			initForm(setBasicInputs, jobApplication);
			initForm(setPaymentInfoInputs, jobApplication);
			initForm(setSkillsInputs, jobApplication);
			initForm(setAdminInputs, jobApplication);
			initForm(setSecurityClearanceInputs, jobApplication);
			setUserLanguagePairs(jobApplication.userLanguages ?? []);
			setDegrees(jobApplication.degrees ?? []);
		}
	}, [
		jobApplication,
		setBasicInputs,
		setPaymentInfoInputs,
		setSkillsInputs,
		setAdminInputs,
		setSecurityClearanceInputs,
		setUserLanguagePairs,
		setDegrees,
	]);

	const saveOrUpdateHandler = () => {
		const jobApplication: IJobApplication = {
			...getInputData(basicInputs, false),
			...getInputData(paymentInfoInputs, false),
			...getInputData(skillsInputs, false),
			...getInputData(adminInputs, false),
			...getInputData(securityClearanceInputs, false),
			userLanguages: userLanguagePairs,
			degrees,
		};
		if (id === "add") {
			dispatch(actions.saveJobApplication(jobApplication));
		} else {
			dispatch(actions.updateJobApplication({ ...jobApplication, id }));
		}
	};

	const status = adminInputs[EJobApplicationAdminInputs.status].value as EJobApplicationStatus;

	if (loading) {
		return <Spinner />;
	}
	return (
		<>
			<JobApplicationToolbar status={status} onSave={saveOrUpdateHandler} />
			<Container>
				<Tabs tabs={tabs} />
				<Switch>
					<Route
						path={createPath(EJobApplicationPaths.basic)}
						render={() => (
							<>
								<JobApplicationBasic inputs={basicInputs} setInputs={setBasicInputs} />
								<Fieldset label="Kielet" transparent>
									<UserLanguagePairs
										onAdd={userLanguagePairAddHandler}
										onChange={userLanguagePairChangeHandler}
										onRemove={userLanguagePairRemoveHandler}
										userLanguagePairs={userLanguagePairs}
									/>
								</Fieldset>
								<JobApplicationPaymentInfo
									inputs={paymentInfoInputs}
									setInputs={setPaymentInfoInputs}
								/>
							</>
						)}
					/>
					<Route
						path={createPath(EJobApplicationPaths.skills)}
						render={() => (
							<>
								<JobApplicationSkills inputs={skillsInputs} setInputs={setSkillsInputs} />
								<Fieldset label="Tutkinnot" transparent>
									<JobApplicationDegrees
										onAdd={degreeAddHandler}
										onChange={degreeChangeHandler}
										onRemove={degreeRemoveHandler}
										degrees={degrees}
									/>
								</Fieldset>
							</>
						)}
					/>
					{(() => {
						// UserRole Hack if more tabs then needs better solution
						if (user?.hasRole(EUserRole.ADMIN)) {
							return (
								<Route
									path={createPath(EJobApplicationPaths.admin)}
									render={() => (
										<>
											<JobApplicationAdmin inputs={adminInputs} setInputs={setAdminInputs} />
											<JobApplicationSecurityClearance
												inputs={securityClearanceInputs}
												setInputs={setSecurityClearanceInputs}
											/>
										</>
									)}
								/>
							);
						}
						return null;
					})()}

					{/* Tää yks idea miten saa kaikki samalle sivulle
					<Route
						path={createPath(EJobApplicationPaths.full)}
						render={() => (
							<>
								<JobApplicationBasic inputs={basicInputs} setInputs={setBasicInputs} />
								<JobApplicationPaymentInfo inputs={paymentInfoInputs} setInputs={setPaymentInfoInputs} />
								<JobApplicationSkills inputs={skillsInputs} setInputs={setSkillsInputs} />
								<Fieldset label="Tutkinnot" transparent>
									<JobApplicationDegrees onAdd={degreeAddHandler} onChange={degreeChangeHandler} onRemove={degreeRemoveHandler} degrees={degrees} />
								</Fieldset>
								<Fieldset label="Kielet" transparent>
									<UserLanguagePairs onAdd={userLanguagePairAddHandler} onChange={userLanguagePairChangeHandler} onRemove={userLanguagePairRemoveHandler} userLanguagePairs={userLanguagePairs} />
								</Fieldset>
								<JobApplicationAdmin inputs={adminInputs} setInputs={setAdminInputs} />
								<JobApplicationSecurityClearance inputs={securityClearanceInputs} setInputs={setSecurityClearanceInputs} />
							</>
						)}
					/>
					*/}
					<Redirect to={createPath(EJobApplicationPaths.basic)} />
				</Switch>
			</Container>
		</>
	);
};

export default JobApplicationEditPage;

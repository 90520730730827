import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import { EJobApplicationAdminInputs } from './useJobApplicationAdminInputs';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>
}

const JobApplicationAdmin: React.FC<IProps> = ({ inputs, setInputs }) => {
	const createInput = useCreateInput(inputs, setInputs);
	return (
		<Fieldset label="Hallinta" transparent>
			{createInput(EJobApplicationAdminInputs.status)}
			{createInput(EJobApplicationAdminInputs.interviewDate)}
			{createInput(EJobApplicationAdminInputs.interviewText)}
			{createInput(EJobApplicationAdminInputs.notesText)}
			{createInput(EJobApplicationAdminInputs.userCreatedDate)}
		</Fieldset>
	);
};

export default JobApplicationAdmin;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IJobApplication } from '../../interfaces/IJobApplication';
import { ETranslation } from '../../translations/translation-keys';
import { formatDateTime } from '../../utils/date-utils';
import Alert from '../ui/Alert/Alert';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import JobApplicationStatus from './JobApplicationStatus';

interface IProps {
	jobApplications: IJobApplication[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const JobApplicationList: React.FC<IProps> = ({ jobApplications, loading, error, onClick }) => {
	const { t } = useTranslation();

	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!jobApplications || loading) {
		return <Spinner />;
	}

	if (jobApplications.length === 0) {
		return <p>{"Ei työhakemuksia"}</p>;
	}

	return (
		<>
			<Table hover>
				<thead>
					<tr>
						<th>Tila</th>
						<th>Luotu</th>
						<th>Päivitetty</th>
						<th>{t(ETranslation.COMMON_NAME)}</th>
					</tr>
				</thead>
				<tbody>
					{jobApplications.map((jobApplication) => (
						<JobApplicationListItem key={jobApplication.id} jobApplication={jobApplication} onClick={onClick} />
					))}
				</tbody>
			</Table>
		</>
	);
};

interface IJobApplicationListItemProps {
	jobApplication: IJobApplication;
	onClick: (id: string) => void;
}

const JobApplicationListItem: React.FC<IJobApplicationListItemProps> = ({ jobApplication, onClick }) => {
	return (
		<tr key={jobApplication.id} onClick={() => onClick(jobApplication.id)}>
			<td><JobApplicationStatus  status={jobApplication.status}/></td>
			<td>{formatDateTime(jobApplication.created)}</td>
			<td>{formatDateTime(jobApplication.updated)}</td>
			<td>{jobApplication.firstName} {jobApplication.lastName}</td>
		</tr>
	);
};

export default JobApplicationList;

import { Dispatch } from 'redux';

import { IJobApplicationSearchParams } from '../../components/JobApplication/JobApplicationSearch';
import { customFetch, EFetchMethod } from '../../custom-fetch';
import { IJobApplication } from '../../interfaces/IJobApplication';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IJobApplicationAction from '../../interfaces/store/IJobApplicationAction';

type TAction = IJobApplicationAction;

const findJobApplicationsStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_FIND_SUCCESS,
  };
};

const findJobApplicationsSuccess = (jobApplications: IJobApplication[]): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_FIND_SUCCESS,
    jobApplications,
  };
};

const findJobApplicationsFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_FIND_FAIL,
    error,
  };
};

export const findJobApplications = (params: IJobApplicationSearchParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(findJobApplicationsStart());
      const jobApplications = await customFetch<IJobApplication[]>(`/jobapplications/list`, EFetchMethod.POST, JSON.stringify(params));
      dispatch(findJobApplicationsSuccess(jobApplications));
    } catch (error) {
      dispatch(findJobApplicationsFail((error as Error).message));
    }
  };
};

const getJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_START,
  };
};

const getJobApplicationSuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_SUCCESS,
    jobApplication,
  };
};

const getJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_FAIL,
    error,
  };
};

export const getJobApplicationClear = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_CLEAR,
  };
};

export const getJobApplication = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getJobApplicationStart());
      const jobApplication = await customFetch<IJobApplication>(`/jobapplications/get?id=${id}`);
      dispatch(getJobApplicationSuccess(jobApplication));
    } catch (error) {
      dispatch(getJobApplicationFail((error as Error).message));
    }
  };
};

const deleteJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_START,
  };
};

const deleteJobApplicationSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_SUCCESS,
    id,
  };
};

const deleteJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_FAIL,
    error,
  };
};

export const deleteJobApplicationClear = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_CLEAR,
  };
};

export const deleteJobApplication = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteJobApplicationStart());
      await customFetch<boolean>(`/jobapplication/delete?id=${id}`, EFetchMethod.DELETE);
      dispatch(deleteJobApplicationSuccess(id));
    } catch (error) {
      dispatch(deleteJobApplicationFail((error as Error).message));
    }
  };
};

const saveJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_START,
  };
};

const saveJobApplicationSuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_SUCCESS,
    jobApplication
  };
};

const saveJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_FAIL,
    error,
  };
};



export const saveJobApplication = (jobApplication: IJobApplication) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(saveJobApplicationStart());
      jobApplication = await customFetch<IJobApplication>("/jobapplications/add", EFetchMethod.POST, JSON.stringify(jobApplication));
      dispatch(saveJobApplicationSuccess(jobApplication));
    } catch (error) {
      dispatch(saveJobApplicationFail((error as Error).message));
    }
  };
};

const updateJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_START,
  };
};

const updateJobApplicationSuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_SUCCESS,
    jobApplication
  };
};

const updateJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_FAIL,
    error,
  };
};

export const updateJobApplication = (jobApplication: IJobApplication) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateJobApplicationStart());
      jobApplication = await customFetch<IJobApplication>("/jobapplications/update", EFetchMethod.PUT, JSON.stringify(jobApplication));
      dispatch(updateJobApplicationSuccess(jobApplication));
    } catch (error) {
      dispatch(updateJobApplicationFail((error as Error).message));
    }
  };
};

export const saveOrUpdateJobApplicationClear = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_OR_UPDATE_CLEAR
  }
}


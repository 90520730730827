import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ERoute, Routes } from '../../classes/Routes';
import { useCreateInput } from '../../hooks/useCreateInput';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { searchJobApplications } from '../../services/jobApplicationService';
import { ETranslation } from '../../translations/translation-keys';
import Button, { EButtonSize } from '../ui/Button/Button';
import Input, { EInputType, IInputField, IOption } from '../ui/Input/Input';
import { getInputData, validateInputs } from '../ui/Input/input-utils';
import InputGroup from '../ui/InputGroup/InputGroup';

export interface IJobApplicationSearchParams {
	startDate: Date;
	endDate: Date;
}

interface IProps {
	onSearch: (params: IJobApplicationSearchParams) => void;
}

enum EInputs {
	startDate = "startDate",
	endDate = "endDate",
}

const JobApplicationSearch: React.FC<IProps> = ({ onSearch }) => {
	const history = useHistory();
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.startDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: dateFns.formatISO(dateFns.startOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
		[EInputs.endDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: dateFns.formatISO(dateFns.endOfMonth(new Date()), { representation: "date" }),
			validation: {
				required: true,
			},
		},
	});

	const createInput = useCreateInput(inputs, setInputs, { showValidation: true });
	const [isValid, setIsValid] = useState(false);
	const { t } = useTranslation();

	const searchHandler = () => {
		onSearch(getInputData<IJobApplicationSearchParams>(inputs));
	};

	useEffectOnce(() => {
		searchHandler();
	});

	useEffect(() => {
		setIsValid(validateInputs(inputs));
	}, [inputs]);



	return (
		<div style={{ marginBottom: '1rem'}}>
			<Input
				inputName='jobApplicationSearch'
				onChange={(value) => {
					if (value) {
						history.push(Routes.withParams(ERoute.JOB_APPLICATION_EDIT, { id: (value as IOption).value}))
					}
				}}
				type={EInputType.reactSelectSearch}
				value={null}
				label="Hae hakemuksista"
				placeholder="Hae hakemuksista (nimi, sähköposti, puhelinnumero)"
				fetchOptions={searchJobApplications}
			/>
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			<Button onClick={searchHandler} size={EButtonSize.SMALL} disabled={!isValid}>
				{t(ETranslation.COMMON_SEARCH)}
			</Button>
		</div>
	);
};

export default JobApplicationSearch;

import { IOption } from "../components/ui/Input/Input";
import { ETranslation } from "../translations/translation-keys";

export const SKILL_LEVEL_OPTIONS: IOption[] = [
	{
		value: "UNKNOWN",
		labelTranslation: ETranslation.COMMON_UNKOWN,
	},
	{
		value: "EVERYDAY",
		labelTranslation: ETranslation.COMMISSIONS_EVERYDAY,
	},
	{
		value: "PROFESSIONAL",
		labelTranslation: ETranslation.COMMISSIONS_PROFESSIONAL,
	},
];

export const SECURITY_CLEARANCE_OPTIONS: IOption[] = [
	{
		value: "UNKNOWN",
		labelTranslation: ETranslation.COMMON_UNKOWN,
	},
	{
		value: "YES",
		labelTranslation: ETranslation.COMMON_YES,
	},
	{
		value: "NO",
		labelTranslation: ETranslation.COMMON_NO,
	},
	{
		value: "NOT_UP_TO_DATE",
		labelTranslation: ETranslation.COMMISSIONS_NO_UP_TO_DATE,
	},
];

export enum EJobApplicationStatus {
	NEW = "NEW",
	OPEN = "OPEN",
	ACCEPTED = "ACCEPTED",
	CONTRACT_SEND = "CONTRACT_SEND",
	CONTRACT_OPEN = "CONTRACT_OPEN",
	CONTRACT_COMPLETED = "CONTRACT_COMPLETED",
	REJECTED = "REJECTED",
}

export const JOB_APPLICATION_STATUS_OPTIONS: IOption[] = [
	{
		value: EJobApplicationStatus.NEW,
		labelTranslation: ETranslation.COMMON_NEW,
	},
	{
		value: EJobApplicationStatus.OPEN,
		labelTranslation: ETranslation.COMMON_OPEN,
	},
	{
		value: EJobApplicationStatus.ACCEPTED,
		labelTranslation: ETranslation.COMMON_ACCEPTED,
	},
	{
		value: EJobApplicationStatus.CONTRACT_SEND,
		labelTranslation: ETranslation.COMMISSIONS_CONTRACT_SEND,
	},
	{
		value: EJobApplicationStatus.CONTRACT_OPEN,
		labelTranslation: ETranslation.COMMISSIONS_CONTRACT_OPEN,
	},
	{
		value: EJobApplicationStatus.CONTRACT_COMPLETED,
		labelTranslation: ETranslation.COMMISSIONS_CONTRACT_COMPLETED,
	},
	{
		value: EJobApplicationStatus.REJECTED,
		labelTranslation: ETranslation.COMMON_REJECTED,
	},
];

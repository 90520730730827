import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { SECURITY_CLEARANCE_OPTIONS } from "../../shared/job-application-data";


export enum EJobApplicationSecurityClearanceInputs {
	securityClearanceStatus = "securityClearanceStatus",
	securityClearanceDate = "securityClearanceDate",
	securityClearanceText = "securityClearanceText",
}

export const useJobApplicationSecurityClearanceInputs = () => {
	const [securityClearanceInputs, setSecurityClearanceInputs] = useState<IInputField>({
		[EJobApplicationSecurityClearanceInputs.securityClearanceStatus]: {
			type: EInputType.radio,
			label: "Tila",
			options: SECURITY_CLEARANCE_OPTIONS,
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSecurityClearanceInputs.securityClearanceText]: {
			type: EInputType.textarea,
			label: "Lisätiedot",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationSecurityClearanceInputs.securityClearanceDate]: {
			type: EInputType.date,
			label: "Päivämäärä",
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { securityClearanceInputs, setSecurityClearanceInputs };
}
import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { GENDER_OPTIONS } from "../../shared/common-data";


export enum EJobApplicationBasicInput {
	firstName = "firstName",
	lastName = "lastName",
	email = "email",
	phoneNumber = "phoneNumber",
	gender = "gender",
	address = "address",
	zip = "zip",
	city = "city",
}

export const useJobApplicationBasicInputs = () => {
	const [basicInputs, setBasicInputs] = useState<IInputField>({
		[EJobApplicationBasicInput.firstName]: {
			type: EInputType.text,
			label: "Etunimi",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.lastName]: {
			type: EInputType.text,
			label: "Sukunimi",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.email]: {
			type: EInputType.email,
			label: "Sähköposti",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.phoneNumber]: {
			type: EInputType.tel,
			label: "Puhelinnumero",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.gender]: {
			type: EInputType.radio,
			label: "Sukupuoli",
			value: "",
			options: GENDER_OPTIONS,
		},
		[EJobApplicationBasicInput.address]: {
			type: EInputType.text,
			label: "Osoite",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.zip]: {
			type: EInputType.text,
			label: "Postinumero",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationBasicInput.city]: {
			type: EInputType.text,
			label: "Kaupunki",
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { basicInputs, setBasicInputs };
}
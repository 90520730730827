enum EActionTypes {
	// AuthActions START
	AUTH_GET_USER_START,
	AUTH_GET_USER_SUCCESS,
	AUTH_GET_USER_FAIL,
	// LOGIN
	AUTH_LOGIN_START,
	AUTH_LOGIN_SUCCESS,
	AUTH_LOGIN_FAIL,
	// Current SIte
	AUTH_GET_SITE_START,
	AUTH_GET_SITE_SUCCESS,
	AUTH_GET_SITE_FAIL,
	AUTH_GET_SITE_CLEAR,
	// End of login
	// AuthActions END
	// CommonActions START
	COMMON_FIND_LANGUAGES_START,
	COMMON_FIND_LANGUAGES_SUCCESS,
	COMMON_FIND_LANGUAGES_FAIL,
	COMMON_GET_LANGUAGES_STATUS_START,
	COMMON_GET_LANGUAGES_STATUS_SUCCESS,
	COMMON_GET_LANGUAGES_STATUS_FAIL,
	COMMON_GET_LANGUAGES_STATUS_CLEAR,
	// CommonActons END
	// PriceSeasonActions START
	PRICE_SEASON_SAVE_START,
	PRICE_SEASON_SAVE_SUCCESS,
	PRICE_SEASON_SAVE_FAIL,

	PRICE_SEASON_COPY_START,
	PRICE_SEASON_COPY_SUCCESS,
	PRICE_SEASON_COPY_FAIL,
	PRICE_SEASON_COPY_CLEAR,

	PRICE_SEASON_UPDATE_START,
	PRICE_SEASON_UPDATE_SUCCESS,
	PRICE_SEASON_UPDATE_FAIL,

	PRICE_SEASON_SAVE_OR_UPDATE_CLEAR,

	PRICE_SEASON_GET_START,
	PRICE_SEASON_GET_SUCCESS,
	PRICE_SEASON_GET_FAIL,
	PRICE_SEASON_GET_CLEAR,

	PRICE_SEASON_FIND_START,
	PRICE_SEASON_FIND_SUCCESS,
	PRICE_SEASON_FIND_FAIL,

	PRICE_SEASON_DELETE_START,
	PRICE_SEASON_DELETE_SUCCESS,
	PRICE_SEASON_DELETE_FAIL,
	PRICE_SEASON_DELETE_CLEAR,

	PRICE_SEASON_RULE_GET_START,
	PRICE_SEASON_RULE_GET_SUCCESS,
	PRICE_SEASON_RULE_GET_FAIL,
	PRICE_SEASON_RULE_GET_CLEAR,

	PRICE_SEASON_RULE_SAVE_START,
	PRICE_SEASON_RULE_SAVE_SUCCESS,
	PRICE_SEASON_RULE_SAVE_FAIL,

	PRICE_SEASON_RULE_DELETE_START,
	PRICE_SEASON_RULE_DELETE_SUCCESS,
	PRICE_SEASON_RULE_DELETE_FAIL,
	PRICE_SEASON_RULE_DELETE_CLEAR,

	PRICE_SEASON_RULE_UPDATE_START,
	PRICE_SEASON_RULE_UPDATE_SUCCESS,
	PRICE_SEASON_RULE_UPDATE_FAIL,

	PRICE_SEASON_RULE_SAVE_OR_UPDATE_CLEAR,

	PRICE_SEASON_RULE_FIND_START,
	PRICE_SEASON_RULE_FIND_SUCCESS,
	PRICE_SEASON_RULE_FIND_FAIL,
	// PriceSeasonActions END
	// OrganizationActions START
	ORGANIZATION_GROUP_SAVE_OR_UPDATE_START,
	ORGANIZATION_GROUP_SAVE_OR_UPDATE_SUCCESS,
	ORGANIZATION_GROUP_SAVE_OR_UPDATE_FAIL,
	ORGANIZATION_GROUP_SAVE_OR_UPDATE_CLEAR,

	ORGANIZATION_GROUP_GET_START,
	ORGANIZATION_GROUP_GET_SUCCESS,
	ORGANIZATION_GROUP_GET_FAIL,
	ORGANIZATION_GROUP_GET_CLEAR,

	ORGANIZATION_GROUP_FIND_START,
	ORGANIZATION_GROUP_FIND_SUCCESS,
	ORGANIZATION_GROUP_FIND_FAIL,

	ORGANIZATION_GROUP_DELETE_START,
	ORGANIZATION_GROUP_DELETE_SUCCESS,
	ORGANIZATION_GROUP_DELETE_FAIL,
	ORGANIZATION_GROUP_DELETE_CLEAR,

	ORGANIZATION_COMPANY_FIND_START,
	ORGANIZATION_COMPANY_FIND_SUCCESS,
	ORGANIZATION_COMPANY_FIND_FAIL,
	ORGANIZATION_COMPANY_CLEAR,

	ORGANIZATION_COMPANY_GET_START,
	ORGANIZATION_COMPANY_GET_SUCCESS,
	ORGANIZATION_COMPANY_GET_FAIL,
	ORGANIZATION_COMPANY_GET_CLEAR,

	ORGANIZATION_COMPANY_DELETE_START,
	ORGANIZATION_COMPANY_DELETE_SUCCESS,
	ORGANIZATION_COMPANY_DELETE_FAIL,
	ORGANIZATION_COMPANY_DELETE_CLEAR,

	ORGANIZATION_COMPANY_SAVE_OR_UPDATE_START,
	ORGANIZATION_COMPANY_SAVE_OR_UPDATE_SUCCESS,
	ORGANIZATION_COMPANY_SAVE_OR_UPDATE_FAIL,
	ORGANIZATION_COMPANY_SAVE_OR_UPDATE_CLEAR,

	// ORGANIZATION TO NETVISOR
	ORGANIZATION_CREATE_IN_NETVISOR_START,
	ORGANIZATION_CREATE_IN_NETVISOR_SUCCESS,
	ORGANIZATION_CREATE_IN_NETVISOR_ERROR,
	// OrganizationActions END
	// SiteActions START
	SITE_SAVE_OR_UPDATE_START,
	SITE_SAVE_OR_UPDATE_SUCCESS,
	SITE_SAVE_OR_UPDATE_FAIL,
	SITE_SAVE_OR_UPDATE_CLEAR,

	SITE_GET_START,
	SITE_GET_SUCCESS,
	SITE_GET_FAIL,
	SITE_GET_CLEAR,

	// save/update settings
	NETVISOR_SETTINGS_SAVE_START,
	NETVISOR_SETTINGS_SAVE_SUCCESS,
	NETVISOR_SETTINGS_SAVE_FAIL,
	NETVISOR_SETTINGS_SAVE_CLEAR,
	// Get netvisor settings
	NETVISOR_SETTINGS_GET_START,
	NETVISOR_SETTINGS_GET_SUCCESS,
	NETVISOR_SETTINGS_GET_FAIL,
	NETVISOR_SETTINGS_GET_CLEAR,

	SITE_FIND_START,
	SITE_FIND_SUCCESS,
	SITE_FIND_FAIL,

	SITE_DELETE_START,
	SITE_DELETE_SUCCESS,
	SITE_DELETE_FAIL,
	SITE_DELETE_CLEAR,
	// SiteActions END
	// TextMessageActions START
	TEXT_MESSAGE_SAVE_OR_UPDATE_START,
	TEXT_MESSAGE_SAVE_OR_UPDATE_SUCCESS,
	TEXT_MESSAGE_SAVE_OR_UPDATE_FAIL,
	TEXT_MESSAGE_SAVE_OR_UPDATE_CLEAR,

	TEXT_MESSAGE_GET_START,
	TEXT_MESSAGE_GET_SUCCESS,
	TEXT_MESSAGE_GET_FAIL,
	TEXT_MESSAGE_GET_CLEAR,

	TEXT_MESSAGE_FIND_START,
	TEXT_MESSAGE_FIND_SUCCESS,
	TEXT_MESSAGE_FIND_FAIL,

	TEXT_MESSAGE_DELETE_START,
	TEXT_MESSAGE_DELETE_SUCCESS,
	TEXT_MESSAGE_DELETE_FAIL,
	TEXT_MESSAGE_DELETE_CLEAR,

	TEXT_MESSAGE_SEND_START,
	TEXT_MESSAGE_SEND_SUCCESS,
	TEXT_MESSAGE_SEND_FAIL,
	TEXT_MESSAGE_SEND_CLEAR,
	// TextMessageActions END
	// NewsItemActions START
	NEWS_ITEM_SAVE_OR_UPDATE_START,
	NEWS_ITEM_SAVE_OR_UPDATE_SUCCESS,
	NEWS_ITEM_SAVE_OR_UPDATE_FAIL,
	NEWS_ITEM_SAVE_OR_UPDATE_CLEAR,

	NEWS_ITEM_GET_START,
	NEWS_ITEM_GET_SUCCESS,
	NEWS_ITEM_GET_FAIL,
	NEWS_ITEM_GET_CLEAR,

	NEWS_ITEM_FIND_START,
	NEWS_ITEM_FIND_SUCCESS,
	NEWS_ITEM_FIND_FAIL,

	NEWS_ITEM_DELETE_START,
	NEWS_ITEM_DELETE_SUCCESS,
	NEWS_ITEM_DELETE_FAIL,
	NEWS_ITEM_DELETE_CLEAR,

	NEWS_ITEM_REVIEW_SAVE_START,
	NEWS_ITEM_REVIEW_SAVE_SUCCESS,
	NEWS_ITEM_REVIEW_SAVE_FAIL,
	NEWS_ITEM_REVIEW_SAVE_CLEAR,
	// NewsItemActions END
	// AttachmentActions START
	ATTACHMENT_GET_START,
	ATTACHMENT_GET_SUCCESS,
	ATTACHMENT_GET_FAIL,
	ATTACHMENT_GET_CLEAR,
	// AttachmentActions END
	// MessageActions START
	MESSAGE_GROUP_SAVE_OR_UPDATE_START,
	MESSAGE_GROUP_SAVE_OR_UPDATE_SUCCESS,
	MESSAGE_GROUP_SAVE_OR_UPDATE_FAIL,

	MESSAGE_GROUP_GET_START,
	MESSAGE_GROUP_GET_SUCCESS,
	MESSAGE_GROUP_GET_FAIL,
	MESSAGE_GROUP_GET_CLEAR,

	MESSAGE_GROUP_FIND_START,
	MESSAGE_GROUP_FIND_SUCCESS,
	MESSAGE_GROUP_FIND_FAIL,

	MESSAGE_GROUP_DELETE_START,
	MESSAGE_GROUP_DELETE_SUCCESS,
	MESSAGE_GROUP_DELETE_FAIL,
	MESSAGE_GROUP_DELETE_CLEAR,

	MESSAGE_GET_START,
	MESSAGE_GET_SUCCESS,
	MESSAGE_GET_FAIL,
	MESSAGE_GET_CLEAR,

	MESSAGE_SAVE_OR_UPDATE_START,
	MESSAGE_SAVE_OR_UPDATE_SUCCESS,
	MESSAGE_SAVE_OR_UPDATE_FAIL,

	MESSAGE_SAVE_OR_UPDATE_CLEAR,

	MESSAGE_DELETE_START,
	MESSAGE_DELETE_SUCCESS,
	MESSAGE_DELETE_FAIL,
	MESSAGE_DELETE_CLEAR,
	// MEssageActions END
	// InfoContactRequestActions START
	INFO_CONTACT_REQUEST_SAVE_START,
	INFO_CONTACT_REQUEST_SAVE_SUCCESS,
	INFO_CONTACT_REQUEST_SAVE_FAIL,

	INFO_CONTACT_REQUEST_UPDATE_START,
	INFO_CONTACT_REQUEST_UPDATE_SUCCESS,
	INFO_CONTACT_REQUEST_UPDATE_FAIL,

	INFO_CONTACT_REQUEST_SAVE_OR_UPDATE_CLEAR,

	INFO_CONTACT_REQUEST_GET_START,
	INFO_CONTACT_REQUEST_GET_SUCCESS,
	INFO_CONTACT_REQUEST_GET_FAIL,
	INFO_CONTACT_REQUEST_GET_CLEAR,

	INFO_CONTACT_REQUEST_FIND_START,
	INFO_CONTACT_REQUEST_FIND_SUCCESS,
	INFO_CONTACT_REQUEST_FIND_FAIL,

	INFO_CONTACT_REQUEST_DELETE_START,
	INFO_CONTACT_REQUEST_DELETE_SUCCESS,
	INFO_CONTACT_REQUEST_DELETE_FAIL,
	INFO_CONTACT_REQUEST_DELETE_CLEAR,

	// LogItemActions START
	LOG_ITEM_SAVE_OR_UPDATE_START,
	LOG_ITEM_SAVE_OR_UPDATE_SUCCESS,
	LOG_ITEM_SAVE_OR_UPDATE_FAIL,
	LOG_ITEM_SAVE_OR_UPDATE_CLEAR,

	LOG_ITEM_GET_START,
	LOG_ITEM_GET_SUCCESS,
	LOG_ITEM_GET_FAIL,
	LOG_ITEM_GET_CLEAR,

	LOG_ITEM_FIND_START,
	LOG_ITEM_FIND_SUCCESS,
	LOG_ITEM_FIND_FAIL,
	LOG_ITEM_FIND_CLEAR,

	LOG_ITEM_DELETE_START,
	LOG_ITEM_DELETE_SUCCESS,
	LOG_ITEM_DELETE_FAIL,
	LOG_ITEM_DELETE_CLEAR,
	// LogItemActions END
	INTERPRETATION_SAVE_OR_UPDATE_START,
	INTERPRETATION_SAVE_OR_UPDATE_SUCCESS,
	INTERPRETATION_SAVE_OR_UPDATE_FAIL,
	INTERPRETATION_SAVE_OR_UPDATE_CLEAR,

	INTERPRETATION_GET_START,
	INTERPRETATION_GET_SUCCESS,
	INTERPRETATION_GET_FAIL,
	INTERPRETATION_GET_CLEAR,

	INTERPRETATION_FIND_START,
	INTERPRETATION_FIND_SUCCESS,
	INTERPRETATION_FIND_FAIL,
	INTERPRETATION_FIND_CLEAR,

	INTERPRETATION_DELETE_START,
	INTERPRETATION_DELETE_SUCCESS,
	INTERPRETATION_DELETE_FAIL,
	INTERPRETATION_DELETE_CLEAR,

	INTERPRETATION_REQUEST_START,
	INTERPRETATION_REQUEST_SUCCESS,
	INTERPRETATION_REQUEST_FAIL,
	INTERPRETATION_REQUEST_CLEAR,

	INTERPRETATION_INTERRUPT_START,
	INTERPRETATION_INTERRUPT_SUCCESS,
	INTERPRETATION_INTERRUPT_FAIL,
	INTERPRETATION_INTERRUPT_CLEAR,

	INTERPRETATION_REVIEW_START,
	INTERPRETATION_REVIEW_SUCCESS,
	INTERPRETATION_REVIEW_FAIL,

	INTERPRETATION_UPDATE_PROPERTY_START,
	INTERPRETATION_UPDATE_PROPERTY_SUCCESS,
	INTERPRETATION_UPDATE_PROPERTY_FAIL,

	ADD_FAVORITE_INTERPRETER_FAIL,
	ADD_FAVORITE_INTERPRETER_SUCCESS,
	ADD_FAVORITE_INTERPRETER_START,

	INTERPRETATION_ACTION_FAIL,
	INTERPRETATION_ACTION_SUCCESS,
	INTERPRETATION_ACTION_START,
	INTERPRETATION_ACTION_CLEAR,

	INTERPRETATION_MESSAGEGROUP_GET_START,
	INTERPRETATION_MESSAGEGROUP_GET_SUCCESS,
	INTERPRETATION_MESSAGEGROUP_GET_FAIL,
	INTERPRETATION_MESSAGEGROUP_GET_CLEAR,

	// Order confirmations
	INTERPRETATION_CONFIRMATION_EMAIL_START,
	INTERPRETATION_CONFIRMATION_EMAIL_SUCCESS,
	INTERPRETATION_CONFIRMATION_EMAIL_ERROR,
	INTERPRETATION_CONFIRMATION_EMAIL_RESET,

	INTERPRETATION_RESET_START,
	INTERPRETATION_RESET_SUCCESS,
	INTERPRETATION_RESET_ERROR,
	INTERPRETATION_RESET_CLEAR,

	// Delete price row
	INTERPRETATION_DELETE_PRICE_ROW_START,
	INTERPRETATION_DELETE_PRICE_ROW_SUCCESS,
	INTERPRETATION_DELETE_PRICE_ROW_ERROR,

	// Payment Data eezy/truster
	INTERPRETATION_GENERATE_PAYMENT_DATA_START,
	INTERPRETATION_GENERATE_PAYMENT_DATA_SUCCESS,
	INTERPRETATION_GENERATE_PAYMENT_DATA_ERROR,

	// Share to sheet
	INTERPRETATION_GENERATE_PAYMENT_SHEET_START,
	INTERPRETATION_GENERATE_PAYMENT_SHEET_SUCCESS,
	INTERPRETATION_GENERATE_PAYMENT_SHEET_ERROR,

	// Generate summaries
	INTERPRETATION_GENERATE_SUMMARIES_START,
	INTERPRETATION_GENERATE_SUMMARIES_SUCCESS,
	INTERPRETATION_GENERATE_SUMMARIES_ERROR,

	// FeedbackSurveys
	INTERPRETATION_FEEDBACK_SURVEY_START,
	INTERPRETATION_FEEDBACK_SURVEY_SUCCESS,
	INTERPRETATION_FEEDBACK_SURVEY_ERROR,
	INTERPRETATION_FEEDBACK_SURVEY_CLEAR,

	// Move to netvisor
	INTERPRETATION_MOVE_TO_NETVISOR_START,
	INTERPRETATION_MOVE_TO_NETVISOR_SUCCESS,
	INTERPRETATION_MOVE_TO_NETVISOR_ERROR,

	//User
	USER_GET_USER_START,
	USER_GET_USER_SUCCESS,
	USER_GET_USER_FAIL,
	USER_GET_USER_CLEAR,
	USER_GET_LIST_START,
	USER_GET_LIST_SUCCESS,
	USER_GET_LIST_FAIL,
	USER_GET_LIST_CLEAR,
	USER_SAVE_USER_START,
	USER_SAVE_USER_SUCCESS,
	USER_SAVE_USER_ERROR,

	USER_GET_CONTRACT_SETTINGS_START,
	USER_GET_CONTRACT_SETTINGS_SUCCESS,
	USER_GET_CONTRACT_SETTINGS_FAIL,
	USER_GET_CONTRACT_SETTINGS_CLEAR,


	USER_RESET_SEND_PASSWORD_START,
	USER_RESET_SEND_PASSWORD_SUCCESS,
	USER_RESET_SEND_PASSWORD_ERROR,
	USER_RESET_SEND_PASSWORD_CLEAR,

	USER_RESET_EEZY_TRUSTER_ID_START,
	USER_RESET_EEZY_TRUSTER_ID_SUCCESS,
	USER_RESET_EEZY_TRUSTER_ID_ERROR,
	USER_RESET_EEZY_TRUSTER_ID_CLEAR,

	// Move user to netvisor
	USER_MOVE_TO_NETVISOR_START,
	USER_MOVE_TO_NETVISOR_SUCCESS,
	USER_MOVE_TO_NETVISOR_ERROR,

	// Remove User
	USER_REMOVE_START,
	USER_REMOVE_SUCCESS,
	USER_REMOVE_ERROR,

	// Comment
	COMMENT_GET_LIST_START,
	COMMENT_GET_LIST_SUCCESS,
	COMMENT_GET_LIST_FAIL,
	COMMENT_GET_LIST_CLEAR,

	// Tags
	TAGS_GET_START,
	TAGS_GET_SUCCESS,
	TAGS_GET_ERROR,
	TAGS_GET_CLEAR,
	// Tag
	TAG_GET_START,
	TAG_GET_SUCCESS,
	TAG_GET_ERROR,
	TAG_GET_CLEAR,

	TAG_SAVE_START,
	TAG_SAVE_SUCCESS,
	TAG_SAVE_ERROR,
	TAG_SAVE_CLEAR,

	// Fees
	FEES_GET_START,
	FEES_GET_SUCCESS,
	FEES_GET_ERROR,
	FEES_GET_CLEAR,

	FEE_GET_START,
	FEE_GET_SUCCESS,
	FEE_GET_ERROR,
	FEE_GET_CLEAR,

	FEE_SAVE_START,
	FEE_SAVE_SUCCESS,
	FEE_SAVE_ERROR,
	FEE_SAVE_CLEAR,

	FEE_DELETE_START,
	FEE_DELETE_SUCCESS,
	FEE_DELETE_ERROR,
	FEE_DELETE_CLEAR,

	// Orders
	ORDERS_GET_START,
	ORDERS_GET_SUCCESS,
	ORDERS_GET_ERROR,
	ORDERS_GET_CLEAR,
	// Order
	ORDER_GET_START,
	ORDER_GET_SUCCESS,
	ORDER_GET_ERROR,
	ORDER_GET_CLEAR,

	ORDER_SAVE_START,
	ORDER_SAVE_SUCCESS,
	ORDER_SAVE_ERROR,
	ORDER_SAVE_CLEAR,

	ORDER_EXPORT_TO_YOUPRET_PAY_START,
	ORDER_EXPORT_TO_YOUPRET_PAY_SUCCESS,
	ORDER_EXPORT_TO_YOUPRET_PAY_FAIL,

	ORDER_UPDATE_PAYMENT_STATUS_START,
	ORDER_UPDATE_PAYMENT_STATUS_SUCCESS,
	ORDER_UPDATE_PAYMENT_STATUS_FAIL,

	//Tasks
	TASKS_GET_START,
	TASKS_GET_SUCCESS,
	TASKS_GET_ERROR,
	TASKS_GET_CLEAR,
	//Task
	TASK_GET_START,
	TASK_GET_SUCCESS,
	TASK_GET_ERROR,
	TASK_GET_CLEAR,

	TASK_SAVE_START,
	TASK_SAVE_SUCCESS,
	TASK_SAVE_ERROR,
	TASK_SAVE_CLEAR,

	// Commission
	COMMISSION_GET_COMMISSION_START,
	COMMISSION_GET_COMMISSION_SUCCESS,
	COMMISSION_GET_COMMISSION_FAIL,
	COMMISSION_GET_LIST_START,
	COMMISSION_GET_LIST_SUCCESS,
	COMMISSION_GET_LIST_NO_PAGINATION,
	COMMISSION_GET_LIST_FAIL,
	COMMISSION_GET_LIST_CLEAR,
	COMMISSION_SAVE_START,
	COMMISSION_SAVE_DONE,
	COMMISSION_SAVE_ERROR,
	COMMISSION_SAVE_CLEAR,

	//Summary
	SUMMARIES_GET_START,
	SUMMARIES_GET_SUCCESS,
	SUMMARIES_GET_ERROR,
	SUMMARIES_GET_CLEAR,

	SUMMARY_GET_START,
	SUMMARY_GET_SUCCESS,
	SUMMARY_GET_ERROR,
	SUMMARY_GET_CLEAR,

	SUMMARY_UPDATE_START,
	SUMMARY_UPDATE_SUCCESS,
	SUMMARY_UPDATE_ERROR,
	SUMMARY_UPDATE_CLEAR,

	SUMMARY_DELETE_START,
	SUMMARY_DELETE_SUCCESS,
	SUMMARY_DELETE_FAIL,
	//Organization
	ORGANIZATION_FIND_START,
	ORGANIZATION_FIND_SUCCESS,
	ORGANIZATION_FIND_FAIL,
	ORGANIZATION_FIND_CLEAR,

	ORGANIZATION_GET_START,
	ORGANIZATION_GET_SUCCESS,
	ORGANIZATION_GET_FAIL,
	ORGANIZATION_GET_CLEAR,

	ORGANIZATION_DELETE_START,
	ORGANIZATION_DELETE_SUCCESS,
	ORGANIZATION_DELETE_FAIL,
	ORGANIZATION_DELETE_CLEAR,

	ORGANIZATION_SAVE_OR_UPDATE_START,
	ORGANIZATION_SAVE_OR_UPDATE_SUCCESS,
	ORGANIZATION_SAVE_OR_UPDATE_FAIL,
	ORGANIZATION_SAVE_OR_UPDATE_CLEAR,

	ORGANIZATION_SEARCH_START,
	ORGANIZATION_SEARCH_SUCCESS,
	ORGANIZATION_SEARCH_FAIL,

	// TwilioActions START
	TWILIO_GET_CONVERSATION_TOKEN_START,
	TWILIO_GET_CONVERSATION_TOKEN_SUCCESS,
	TWILIO_GET_CONVERSATION_TOKEN_FAIL,
	// TwilioActions END

	//SendGridEmail START
	SENDGRIDEMAILS_FIND_START,
	SENDGRIDEMAILS_FIND_SUCCESS,
	SENDGRIDEMAILS_FIND_FAIL,
	//SendGridEmail END

	RESERVED_DATE_FIND_START,
	RESERVED_DATE_FIND_SUCCESS,
	RESERVED_DATE_FIND_FAIL,
	RESERVED_DATE_FIND_CLEAR,

	RESERVED_DATE_DELETE_START,
	RESERVED_DATE_DELETE_SUCCESS,
	RESERVED_DATE_DELETE_FAIL,

	LANGUAGE_CODE_SAVE_OR_UPDATE_START,
	LANGUAGE_CODE_SAVE_OR_UPDATE_SUCCESS,
	LANGUAGE_CODE_SAVE_OR_UPDATE_FAIL,
	LANGUAGE_CODE_SAVE_OR_UPDATE_CLEAR,

	LANGUAGE_CODE_GET_START,
	LANGUAGE_CODE_GET_SUCCESS,
	LANGUAGE_CODE_GET_FAIL,
	LANGUAGE_CODE_GET_CLEAR,

	LANGUAGE_CODE_FIND_START,
	LANGUAGE_CODE_FIND_SUCCESS,
	LANGUAGE_CODE_FIND_FAIL,

	LANGUAGE_CODE_DELETE_START,
	LANGUAGE_CODE_DELETE_SUCCESS,
	LANGUAGE_CODE_DELETE_FAIL,
	LANGUAGE_CODE_DELETE_CLEAR,

	LANGUAGE_SAVE_OR_UPDATE_START,
	LANGUAGE_SAVE_OR_UPDATE_SUCCESS,
	LANGUAGE_SAVE_OR_UPDATE_FAIL,

	LANGUAGE_GET_START,
	LANGUAGE_GET_SUCCESS,
	LANGUAGE_GET_FAIL,

	LANGUAGE_FIND_START,
	LANGUAGE_FIND_SUCCESS,
	LANGUAGE_FIND_FAIL,

	LANGUAGE_DELETE_START,
	LANGUAGE_DELETE_SUCCESS,
	LANGUAGE_DELETE_FAIL,
	LANGUAGE_DELETE_CLEAR,

	LANGUAGE_LINK_START,
	LANGUAGE_LINK_SUCCESS,
	LANGUAGE_LINK_FAIL,

	LANGUAGE_UNLINK_START,
	LANGUAGE_UNLINK_SUCCESS,
	LANGUAGE_UNLINK_FAIL,

	LANGUAGE_UPDATE_TRANSLATION_START,
	LANGUAGE_UPDATE_TRANSLATION_SUCCESS,
	LANGUAGE_UPDATE_TRANSLATION_FAIL,

	LANGUAGE_DELETE_TRANSLATION_START,
	LANGUAGE_DELETE_TRANSLATION_SUCCESS,
	LANGUAGE_DELETE_TRANSLATION_FAIL,

	LANGUAGE_CLEAR,

	WORKING_HOURS_LIST_START,
	WORKING_HOURS_LIST_SUCCESS,
	WORKING_HOURS_LIST_FAIL,
	WORKING_HOURS_LIST_CLEAR,

	WORKING_HOURS_DELETE_START,
	WORKING_HOURS_DELETE_SUCCESS,
	WORKING_HOURS_DELETE_FAIL,
	WORKING_HOURS_DELETE_CLEAR,

	WORKING_HOURS_GET_START,
	WORKING_HOURS_GET_SUCCESS,
	WORKING_HOURS_GET_FAIL,
	WORKING_HOURS_GET_CLEAR,

	WORKING_HOURS_SAVE_START,
	WORKING_HOURS_SAVE_SUCCESS,
	WORKING_HOURS_SAVE_FAIL,

	WORKING_HOURS_UPDATE_START,
	WORKING_HOURS_UPDATE_SUCCESS,
	WORKING_HOURS_UPDATE_FAIL,

	WORKING_HOURS_SAVE_OR_UPDATE_CLEAR,

	WORKING_HOURS_MONTHLY_SUMMARY_LIST_START,
	WORKING_HOURS_MONTHLY_SUMMARY_LIST_SUCCESS,
	WORKING_HOURS_MONTHLY_SUMMARY_LIST_FAIL,
	WORKING_HOURS_MONTHLY_SUMMARY_LIST_CLEAR,

	WORKING_HOUR_EVENTS_LIST_START,
	WORKING_HOUR_EVENTS_LIST_SUCCESS,
	WORKING_HOUR_EVENTS_LIST_FAIL,
	WORKING_HOUR_EVENTS_LIST_CLEAR,

	USER_GROUP_GET_START,
	USER_GROUP_GET_SUCCESS,
	USER_GROUP_GET_FAIL,
	USER_GROUP_GET_CLEAR,

	USER_GROUP_SAVE_START,
	USER_GROUP_SAVE_SUCCESS,
	USER_GROUP_SAVE_FAIL,

	USER_GROUP_DELETE_START,
	USER_GROUP_DELETE_SUCCESS,
	USER_GROUP_DELETE_FAIL,
	USER_GROUP_DELETE_CLEAR,

	USER_GROUP_UPDATE_START,
	USER_GROUP_UPDATE_SUCCESS,
	USER_GROUP_UPDATE_FAIL,

	USER_GROUP_SAVE_OR_UPDATE_CLEAR,

	USER_GROUP_FIND_START,
	USER_GROUP_FIND_SUCCESS,
	USER_GROUP_FIND_FAIL,

	USER_CONTRACT_SETTING_GET_START,
	USER_CONTRACT_SETTING_GET_SUCCESS,
	USER_CONTRACT_SETTING_GET_FAIL,
	USER_CONTRACT_SETTING_GET_CLEAR,

	USER_CONTRACT_SETTING_SAVE_START,
	USER_CONTRACT_SETTING_SAVE_SUCCESS,
	USER_CONTRACT_SETTING_SAVE_FAIL,

	USER_CONTRACT_SETTING_DELETE_START,
	USER_CONTRACT_SETTING_DELETE_SUCCESS,
	USER_CONTRACT_SETTING_DELETE_FAIL,
	USER_CONTRACT_SETTING_DELETE_CLEAR,

	USER_CONTRACT_SETTING_UPDATE_START,
	USER_CONTRACT_SETTING_UPDATE_SUCCESS,
	USER_CONTRACT_SETTING_UPDATE_FAIL,

	USER_CONTRACT_SETTING_SAVE_OR_UPDATE_CLEAR,

	USER_CONTRACT_SETTING_FIND_START,
	USER_CONTRACT_SETTING_FIND_SUCCESS,
	USER_CONTRACT_SETTING_FIND_FAIL,

	USER_SHIFT_GET_START,
	USER_SHIFT_GET_SUCCESS,
	USER_SHIFT_GET_FAIL,
	USER_SHIFT_GET_CLEAR,

	USER_SHIFT_SAVE_START,
	USER_SHIFT_SAVE_SUCCESS,
	USER_SHIFT_SAVE_FAIL,

	USER_SHIFT_DATES_SAVE_START,
	USER_SHIFT_DATES_SAVE_SUCCESS,
	USER_SHIFT_DATES_SAVE_FAIL,
	USER_SHIFT_DATES_SAVE_CLEAR,

	USER_SHIFT_DATES_DELETE_START,
	USER_SHIFT_DATES_DELETE_SUCCESS,
	USER_SHIFT_DATES_DELETE_FAIL,

	USER_SHIFT_DELETE_START,
	USER_SHIFT_DELETE_SUCCESS,
	USER_SHIFT_DELETE_FAIL,
	USER_SHIFT_DELETE_CLEAR,

	USER_SHIFT_UPDATE_START,
	USER_SHIFT_UPDATE_SUCCESS,
	USER_SHIFT_UPDATE_FAIL,

	USER_SHIFT_SAVE_OR_UPDATE_CLEAR,

	USER_SHIFT_FIND_START,
	USER_SHIFT_FIND_SUCCESS,
	USER_SHIFT_FIND_FAIL,

	// eezy/truster
	EEZY_TRUSTER_GET_EVENTS_START,
	EEZY_TRUSTER_GET_EVENTS_DONE,
	EEZY_TRUSTER_GET_EVENTS_ERROR,
	EEZY_TRUSTER_GET_EVENTS_CLEAR,

	EEZY_TRUSTER_GET_EVENT_START,
	EEZY_TRUSTER_GET_EVENT_DONE,
	EEZY_TRUSTER_GET_EVENT_ERROR,
	EEZY_TRUSTER_GET_EVENT_CLEAR,

	EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_START,
	EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_DONE,
	EEZY_TRUSTER_ADD_MANAGEMENT_TOTAL_ERROR,

	EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_START,
	EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_DONE,
	EEZY_TRUSTER_REMOVE_MANAGEMENT_TOTAL_ERROR,

	EEZY_TRUSTER_SEND_PAYMENT_START,
	EEZY_TRUSTER_SEND_PAYMENT_DONE,
	EEZY_TRUSTER_SEND_PAYMENT_ERROR,

	EEZY_TRUSTER_REMOVE_PAYMENT_START,
	EEZY_TRUSTER_REMOVE_PAYMENT_DONE,
	EEZY_TRUSTER_REMOVE_PAYMENT_ERROR,

	EEZY_TRUSTER_SEND_EVENT_START,
	EEZY_TRUSTER_SEND_EVENT_DONE,
	EEZY_TRUSTER_SEND_EVENT_ERROR,

	EEZY_TRUSTER_SEND_NEW_CUSTOM_START,
	EEZY_TRUSTER_SEND_NEW_CUSTOM_DONE,
	EEZY_TRUSTER_SEND_NEW_CUSTOM_ERROR,

	EEZY_TRUSTER_REMOVE_CUSTOM_START,
	EEZY_TRUSTER_REMOVE_CUSTOM_DONE,
	EEZY_TRUSTER_REMOVE_CUSTOM_ERROR,

	EEZY_TRUSTER_GET_SALES_ORDER_START,
	EEZY_TRUSTER_GET_SALES_ORDER_DONE,
	EEZY_TRUSTER_GET_SALES_ORDER_ERROR,

	CATEGORY_GET_START,
	CATEGORY_GET_SUCCESS,
	CATEGORY_GET_FAIL,
	CATEGORY_GET_CLEAR,

	CATEGORY_SAVE_START,
	CATEGORY_SAVE_SUCCESS,
	CATEGORY_SAVE_FAIL,

	CATEGORY_DELETE_START,
	CATEGORY_DELETE_SUCCESS,
	CATEGORY_DELETE_FAIL,
	CATEGORY_DELETE_CLEAR,

	CATEGORY_UPDATE_START,
	CATEGORY_UPDATE_SUCCESS,
	CATEGORY_UPDATE_FAIL,

	CATEGORY_SAVE_OR_UPDATE_CLEAR,

	CATEGORY_FIND_START,
	CATEGORY_FIND_SUCCESS,
	CATEGORY_FIND_FAIL,

	ATTRIBUTE_GET_START,
	ATTRIBUTE_GET_SUCCESS,
	ATTRIBUTE_GET_FAIL,
	ATTRIBUTE_GET_CLEAR,

	ATTRIBUTE_SAVE_START,
	ATTRIBUTE_SAVE_SUCCESS,
	ATTRIBUTE_SAVE_FAIL,

	ATTRIBUTE_DELETE_START,
	ATTRIBUTE_DELETE_SUCCESS,
	ATTRIBUTE_DELETE_FAIL,
	ATTRIBUTE_DELETE_CLEAR,

	ATTRIBUTE_UPDATE_START,
	ATTRIBUTE_UPDATE_SUCCESS,
	ATTRIBUTE_UPDATE_FAIL,

	ATTRIBUTE_SAVE_OR_UPDATE_CLEAR,

	ATTRIBUTE_FIND_START,
	ATTRIBUTE_FIND_SUCCESS,
	ATTRIBUTE_FIND_FAIL,

	EMAIL_ORDER_GET_START,
	EMAIL_ORDER_GET_SUCCESS,
	EMAIL_ORDER_GET_FAIL,
	EMAIL_ORDER_GET_CLEAR,

	EMAIL_ORDER_SAVE_START,
	EMAIL_ORDER_SAVE_SUCCESS,
	EMAIL_ORDER_SAVE_FAIL
	,
	EMAIL_ORDER_DELETE_START,
	EMAIL_ORDER_DELETE_SUCCESS,
	EMAIL_ORDER_DELETE_FAIL,
	EMAIL_ORDER_DELETE_CLEAR,

	EMAIL_ORDER_UPDATE_START,
	EMAIL_ORDER_UPDATE_SUCCESS,
	EMAIL_ORDER_UPDATE_FAIL,

	EMAIL_ORDER_SAVE_OR_UPDATE_CLEAR,

	EMAIL_ORDER_FIND_START,
	EMAIL_ORDER_FIND_SUCCESS,
	EMAIL_ORDER_FIND_FAIL,

	JOB_APPLICATION_GET_START,
	JOB_APPLICATION_GET_SUCCESS,
	JOB_APPLICATION_GET_FAIL,
	JOB_APPLICATION_GET_CLEAR,

	JOB_APPLICATION_SAVE_START,
	JOB_APPLICATION_SAVE_SUCCESS,
	JOB_APPLICATION_SAVE_FAIL,

	JOB_APPLICATION_DELETE_START,
	JOB_APPLICATION_DELETE_SUCCESS,
	JOB_APPLICATION_DELETE_FAIL,
	JOB_APPLICATION_DELETE_CLEAR,

	JOB_APPLICATION_UPDATE_START,
	JOB_APPLICATION_UPDATE_SUCCESS,
	JOB_APPLICATION_UPDATE_FAIL,

	JOB_APPLICATION_SAVE_OR_UPDATE_CLEAR,

	JOB_APPLICATION_FIND_START,
	JOB_APPLICATION_FIND_SUCCESS,
	JOB_APPLICATION_FIND_FAIL,

	MODAL_UPDATING,
	MODAL_UPDATING_OK,
	MODAL_UPDATING_ERROR,

	MODAL_DELETING,
	MODAL_DELETING_OK,
	MODAL_DELETING_ERROR,
}

export default EActionTypes;

import React, { ReactNode, useEffect, useState } from "react";
import { EUserLanguagePairType, IUserLanguagePair } from "../../../../interfaces/IUserLanguagePair";
import { EInputType, IInputField, IOption } from "../../../ui/Input/Input";
import { ETranslation } from "../../../../translations/translation-keys";
import { getInputData, validateInputs } from "../../../ui/Input/input-utils";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import Fieldset from "../../../ui/Fieldset/Fieldset";
import InputGroup from "../../../ui/InputGroup/InputGroup";
import { useLanguages } from "../../../../hooks/useLanguages";
import { LANGUAGE_TYPE_OPTIONS } from "../../../../shared/languages-data";
import { useSingleOptionLabel } from "../../../../hooks/useSingleOptionLabel";
import { useInitFormOnce } from "../../../../hooks/useInitFormOnce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { useAttributesAttach } from "../../../Attributes/useAttributesAttach";
import AttributesAttach from "../../../Attributes/AttributesAttach";

enum EInputs {
	language1 = "language1",
	language2 = "language2",
	type = "type",
	description = "description"
}

interface IProps {
	onChange: (userLanguage: IUserLanguagePair, isValid: boolean) => void;
	onRemove: (id: string) => void;
	userLanguagePair: IUserLanguagePair;
}

const UserLanguagePair: React.FC<IProps> = ({ onChange, userLanguagePair, onRemove }) => {
	const { languageOptions }  = useLanguages();
	const { attributes, attributesDeleteHandler, attributesEditHandler, setAttributes, attributesAddHandler } = useAttributesAttach();

	const id = userLanguagePair.id;

	const { inputs, setInputs } = useInputs();

	useInitFormOnce(userLanguagePair, setInputs, () => {
		setAttributes(userLanguagePair.attributes || []);
	});

	useEffect(() => {
		const newLangPair = getInputData<IUserLanguagePair>(inputs);
		newLangPair.id = id;
		newLangPair.attributes = attributes;
		const isValid = validateInputs(inputs);
		onChange(newLangPair, isValid);
	}, [inputs, onChange, id, attributes]);

	const createInput = useCreateInput(inputs, setInputs);

	const label = useLabel(inputs, languageOptions);

	return (
		<Fieldset label={label} onRemove={() => onRemove(userLanguagePair?.id)}>
			{createInput(EInputs.type)}
			<InputGroup>
				{createInput(EInputs.language1, { options: languageOptions })}
				{createInput(EInputs.language2, { options: languageOptions })}
			</InputGroup>
			<AttributesAttach attributes={attributes} onAdd={attributesAddHandler} onDelete={attributesDeleteHandler} onEdit={attributesEditHandler} />
			{createInput(EInputs.description)}
		</Fieldset>
	);
};

const useInputs = () => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.language1]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.language2]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: "",
			options: LANGUAGE_TYPE_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
	});
	return { inputs, setInputs };
}

const useLabel = (inputs: IInputField, languageOptions: IOption[]) => {
	const type = inputs[EInputs.type].value as string;
	const lang1 = inputs[EInputs.language1].value as string;
	const lang2 = inputs[EInputs.language2].value as string;

	const typeStr = useSingleOptionLabel(LANGUAGE_TYPE_OPTIONS, type);
	const language1 = useSingleOptionLabel(languageOptions, lang1);
	const language2 = useSingleOptionLabel(languageOptions, lang2);

	const isOneWay = [EUserLanguagePairType.TRANSLATION].includes(type as EUserLanguagePairType);

	let label: ReactNode = <span className="text-danger">Valitse kielet</span>;
	if (language1 && language2) {
		label = <>
			{typeStr} - {language1}&nbsp;&nbsp;<FontAwesomeIcon icon={isOneWay ? faArrowRight : faArrowsAltH} />&nbsp;{language2}
		</>;
	}
	return label;
}

export default UserLanguagePair;

import { useState } from "react";
import { EInputType, IInputField } from "../ui/Input/Input";
import { VAT_REGISTER_OPTIONS } from "../../shared/common-data";


export enum EJobApplicationPaymentInfoInputs {
	socialSecurityNumber = "socialSecurityNumber",
	iban = "iban",
	organizationName = "organizationName",
	organizationVatRegister = "organizationVatRegister",
	organizationBusinessId = "organizationBusinessId",
}

export const useJobApplicationPaymentInfoInputs = () => {
	const [paymentInfoInputs, setPaymentInfoInputs] = useState<IInputField>({
		[EJobApplicationPaymentInfoInputs.socialSecurityNumber]: {
			type: EInputType.text,
			label: "Henkilötunnus",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationPaymentInfoInputs.iban]: {
			type: EInputType.text,
			label: "IBAN",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationPaymentInfoInputs.organizationName]: {
			type: EInputType.text,
			label: "Yritys",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationPaymentInfoInputs.organizationVatRegister]: {
			type: EInputType.radio,
			label: "ALV-rekisterissä",
			value: "",
			options: VAT_REGISTER_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EJobApplicationPaymentInfoInputs.organizationBusinessId]: {
			type: EInputType.text,
			label: "Y-tunnus",
			value: "",
			validation: {
				required: true,
			},
		},

	});
	return { paymentInfoInputs, setPaymentInfoInputs };
}
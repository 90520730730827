import { IJobApplication } from '../../interfaces/IJobApplication';
import EActionTypes from '../../interfaces/store/EActionTypes';
import IJobApplicationAction from '../../interfaces/store/IJobApplicationAction';
import IJobApplicationState from '../../interfaces/store/IJobApplicationState';
import { ETranslation } from '../../translations/translation-keys';
import { removeOne } from '../../utils/reducer-utils';
import { insertFirst, updateOne } from '../../utils/reducer-utils';

type ReducerAction = IJobApplicationAction;
type ReducerState = IJobApplicationState;
type ReducerSignature = (state: ReducerState, action: ReducerAction) => ReducerState;

const initialState: ReducerState = {
  jobApplication: null,

  jobApplications: null,
  loading: false,
  error: null,

  deletingError: null,
  deleting: false,
  deletingOk: false,

  saveOrUpdateOk: false
};

const findJobApplicationStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const findJobApplicationSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    jobApplications: action.jobApplications || null,
    error: null,
    loading: false,
  };
};

const findJobApplicationFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, jobApplications: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getJobApplicationStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getJobApplicationSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    jobApplication: action.jobApplication || null,
    error: null,
    loading: false,
  };
};

const getJobApplicationFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, jobApplication: null, loading: false, error: action.error || ETranslation.REDUCER_SEARCH_ERROR };
};


const getJobApplicationClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, jobApplication: null, loading: false, error: null};
};

const deleteJobApplicationStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: true, deletingOk: false, };
};

const deleteJobApplicationSuccess: ReducerSignature = (state, action): ReducerState => {
  const jobApplications = removeOne<IJobApplication>(state.jobApplications, action.id);
  return {
    ...state,
    jobApplications,
    deletingError: null,
    deleting: false,
    deletingOk: true,
  };
};

const deleteJobApplicationFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deleting: false, deletingError: action.error as string, deletingOk: false, };
};

const deleteJobApplicationClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, deletingError: null, deleting: false, deletingOk: false, };
};


const saveJobApplicationStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const saveJobApplicationSuccess: ReducerSignature = (state, action): ReducerState => {
  const jobApplications = insertFirst(state.jobApplications, action.jobApplication)
  return {
    ...state,
    jobApplications,
    error: null,
    loading: false,
    saveOrUpdateOk: true
  };
};

const saveJobApplicationFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const updateJobApplicationStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const updateJobApplicationSuccess: ReducerSignature = (state, action): ReducerState => {
  const jobApplications = updateOne(state.jobApplications, action.jobApplication);
  return {
    ...state,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    jobApplications
  };
};

const updateJobApplicationFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: action.error || ETranslation.REDUCER_SAVE_ERROR };
};

const saveOrUpdateJobApplicationClear: ReducerSignature = (state, action): ReducerState => {
  return { ...state, loading: false, error: null, saveOrUpdateOk: false }
}

const reducer = (state: ReducerState = initialState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case EActionTypes.JOB_APPLICATION_FIND_START:
      return findJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_FIND_SUCCESS:
      return findJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_FIND_FAIL:
      return findJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_GET_START:
      return getJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_GET_SUCCESS:
      return getJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_GET_FAIL:
      return getJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_GET_CLEAR:
      return getJobApplicationClear(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_START:
      return deleteJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_SUCCESS:
      return deleteJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_FAIL:
      return deleteJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_CLEAR:
      return deleteJobApplicationClear(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_START:
      return saveJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_SUCCESS:
      return saveJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_FAIL:
      return saveJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_START:
      return updateJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_SUCCESS:
      return updateJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_FAIL:
      return updateJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_OR_UPDATE_CLEAR:
      return saveOrUpdateJobApplicationClear(state, action)
    default:
      return state;
  }
};

export default reducer;

import React from 'react';

import classes from './JobApplicationToolbar.module.scss';
import Button, { EButtonColor, EButtonSize } from '../ui/Button/Button';
import { EJobApplicationStatus } from '../../shared/job-application-data';
import JobApplicationStatus from './JobApplicationStatus';
import { useHistory } from 'react-router-dom';
import { ERoute } from '../../classes/Routes';


interface IProps {
	status: EJobApplicationStatus;
	onSave: VoidFunction;
}

const JobApplicationToolbar: React.FC<IProps> = ({ status, onSave }) => {
	const history = useHistory();

	return (
		<div className={classes.Container}>
			<div style={{ marginLeft: '1rem' }}>
				<JobApplicationStatus status={status} />
			</div>
			<div className={classes.Actions}>
				<Button onClick={onSave} size={EButtonSize.X_SMALL}>Tallenna</Button>
				<Button onClick={() => history.push(ERoute.JOB_APPLICATION_LIST)}  size={EButtonSize.X_SMALL} color={EButtonColor.DEFAULT}>Peruuta</Button>
			</div>
		</div>
	)
}

export default JobApplicationToolbar;
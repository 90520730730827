import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import InputGroup from '../ui/InputGroup/InputGroup';
import { EJobApplicationPaymentInfoInputs } from './useJobApplicationPaymentInfoInputs';


interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>
}


const JobApplicationPaymentInfo: React.FC<IProps> = ({ inputs, setInputs}) => {
	const createInput = useCreateInput(inputs, setInputs);
	return (
		<Fieldset label="Maksutiedot" transparent>
			<InputGroup>
				{createInput(EJobApplicationPaymentInfoInputs.socialSecurityNumber)}
				{createInput(EJobApplicationPaymentInfoInputs.iban)}
			</InputGroup>
			<InputGroup>
				{createInput(EJobApplicationPaymentInfoInputs.organizationName)}
				{createInput(EJobApplicationPaymentInfoInputs.organizationVatRegister)}
				{createInput(EJobApplicationPaymentInfoInputs.organizationBusinessId)}
			</InputGroup>
		</Fieldset>
	)
}



export default React.memo(JobApplicationPaymentInfo);
import React from 'react';

import { IEmailOrder } from '../../interfaces/IEmailOrder';
import { formatDateTime } from '../../utils/date-utils';
import Alert from '../ui/Alert/Alert';
import Spinner from '../ui/Spinner/Spinner';
import Table from '../ui/Table/Table';
import EmailOrderStatus from './EmailOrderStatus';

interface IProps {
	emailOrders: IEmailOrder[] | null;
	loading: boolean;
	error: string | null;
	onClick: (id: string) => void;
}

const EmailOrderList: React.FC<IProps> = ({ emailOrders, loading, error, onClick }) => {
	if (error) {
		return <Alert>{error}</Alert>;
	}

	if (!emailOrders || loading) {
		return <Spinner />;
	}

	if (emailOrders.length === 0) {
		return <p>{"Ei tilauksia"}</p>;
	}

	return (
		<>
			<Table hover>
				<thead>
					<tr>
						<th>Tila</th>
						<th>Luotu</th>
						<th>Tiedosto</th>
					</tr>
				</thead>
				<tbody>
					{emailOrders.map((emailOrder) => (
						<EmailOrderListItem key={emailOrder.id} emailOrder={emailOrder} onClick={onClick} />
					))}
				</tbody>
			</Table>
		</>
	);
};

interface IEmailOrderListItemProps {
	emailOrder: IEmailOrder;
	onClick: (id: string) => void;
}

const EmailOrderListItem: React.FC<IEmailOrderListItemProps> = ({ emailOrder, onClick }) => {
	return (
		<tr key={emailOrder.id} onClick={() => onClick(emailOrder.id)}>
			<td><EmailOrderStatus status={emailOrder.status}/></td>
			<td>{formatDateTime(emailOrder.created)}</td>
			<td>{emailOrder.attachment?.clientName}</td>
		</tr>
	);
};

export default EmailOrderList;

import { MAX_SIZE } from "../components/Base64Dropzone/Base64Dropzone";
import { DEFAULT_SEARCH_TERM_MIN } from "../components/ui/Select/SelectSearch";
import { ETranslation } from "./translation-keys";

const uiTranslations = {
	[ETranslation.UI_ACTIVEBADGE_ACTIVE]: "Active",
	[ETranslation.UI_ACTIVEBADGE_PASSIVE]: "Passive",
	[ETranslation.UI_EDITBUTTONS_CONFIRM_DELETE]: "Confirm delete",
	[ETranslation.UI_REACT_SEARCH_MIN_LETTERS]: `Enter at least ${DEFAULT_SEARCH_TERM_MIN} characters.`,
	[ETranslation.UI_REACT_SEARCH_NO_RESULTS]: "No search results.",
	[ETranslation.UI_INPUT_PRESS_OR_DROP_IMAGE]: "Press here or drop an image.",
	[ETranslation.UI_INPUT_DROP_IMAGE]: "Drop an image",
	[ETranslation.UI_SAVE]: "Save",
	[ETranslation.UI_CANCEL]: "Cancel",
	[ETranslation.UI_DELETE]: "Delete",
	[ETranslation.UI_SEND]: "Send",
	[ETranslation.UI_CLEAR]: "Clear",
	[ETranslation.UI_MAKE_ORDER]: "Order interpreter",
	[ETranslation.UI_LOADING]: "Loading...",
	[ETranslation.UI_SHOW_DELETED]: "Show deleted",
	[ETranslation.REACT_SELECT_ADD_NEW]: "Add new",
	[ETranslation.REACT_SELECT_PLACEHOLDER]: "Select",
	[ETranslation.NOT_FOUND_ERROR]: "Not found",
};

const menuTranslations = {
	[ETranslation.MENU_ORDER_INTERPRETER]: "Order interpreter",
	[ETranslation.MENU_INTERPRETATIONS]: "Interpretations",
	[ETranslation.MENU_INTERPRETATIONS_LIST]: "Interpretation list",
	[ETranslation.MENU_ORDERS_PAGE]: "Orders",
	[ETranslation.MENU_EMAIL_ORDERS_PAGE]: "Email orders",
	[ETranslation.MENU_ORDER_INTERPRETER_NEW]: "Order interpreter (New)",
	[ETranslation.MENU_PRICE_SEASONS]: "Price seasons",
	[ETranslation.MENU_SITES]: "Sites",
	[ETranslation.MENU_ORGANIZATIONS]: "Organizations",
	[ETranslation.MENU_PRICE_SEASON_RULES]: "Price season rules",
	[ETranslation.MENU_ORGANIZATION_GROUPS]: "Groups",
	[ETranslation.MENU_ORGANIZATION_COMPANIES]: "Companies",
	[ETranslation.MENU_TEXTMESSAGES]: "Text messages",
	[ETranslation.MENU_EMAILS_LOGS]: "Email logs",
	[ETranslation.MENU_NEWS]: "News",
	[ETranslation.MENU_RECORDINGS_AND_TRANSLATIONS]: "Recordings, translations, and attachments",
	[ETranslation.MENU_CONTACT_REQUESTS]: "Contact requests",
	[ETranslation.MENU_SETTINGS]: "Settings",
	[ETranslation.MENU_CUSTOMER_REPORT]: "Monthly report",
	[ETranslation.MENU_ADMIN]: "Admin",
	[ETranslation.MENU_LOG_ITEMS]: "Log items",
	[ETranslation.MENU_RESERVED_DATES]: "Reserved dates",
	[ETranslation.MENU_USERS]: "Users",
	[ETranslation.MENU_USERS_INTERPRETERS]: "Interpreters",
	[ETranslation.MENU_MANAGEMENT]: "Management",
	[ETranslation.MENU_TAGS]: "Tags",
	[ETranslation.MENU_CATEGORIES]: "Categories",
	[ETranslation.MENU_ATTRIBUTES]: "Attributes",
	[ETranslation.MENU_COMISSIONS]: "Potential interpreters",
	[ETranslation.MENU_SUMMARIES]: "Summaries",
	[ETranslation.MENU_TASKS]: "User ID requests",
	[ETranslation.MENU_FEES]: "Fees",
	[ETranslation.MENU_USERS_CUSTOMERS]: "Customers",
	[ETranslation.MENU_USERS_ADMINS]: "Admins",
	[ETranslation.MENU_COMMENTS]: "Comments",
	[ETranslation.MENU_CALENDAR]: "Calendar",
	[ETranslation.MENU_LANGUAGES]: "Language",
	[ETranslation.MENU_FI]: "Finnish",
	[ETranslation.MENU_EN]: "English",
	[ETranslation.MENU_LANGUAGE_CODES]: "language codes",
	[ETranslation.MENU_WORK_HOURS]: "Working hours",
	[ETranslation.MENU_WORK_HOURS_SUMMARIES]: "Working hours summaries",
	[ETranslation.MENU_USER_GROUPS]: "Groups",
	[ETranslation.MENU_USER_CONTRACT_SETTINGS]: "Contract",
	[ETranslation.MENU_EEZY_TRUSTER_EVENTS]: "Eezy/Truster events",
};

const usersTranslations = {
	[ETranslation.USER_PAYMENT_INVOICE]: "Invoice",
	[ETranslation.USER_LAST_SEEN]: "Last seen",
	[ETranslation.USER_ALLOW_INSTANT]: "Allow instant interpretation",
	[ETranslation.USER_ALLOW_ADMIN_FEES]: "Allow fees administration",
	[ETranslation.USER_ALLOW_ADMIN_EEZY_TRUSTER]: "Allow Eezy/Truster administration",
	[ETranslation.USER_ALLOW_SUMMARIES]: "Allow Summaries",
	[ETranslation.USER_ALLOW_WORKING_HOURS_DATE]: "Allow working hours",
	[ETranslation.USER_ALLOW_BOOKING]: "Allow booking",
	[ETranslation.USER_ALLOW_MACHINE]: "Allow machine interpretation",
	[ETranslation.USER_ALLOW_MULTIPLE_DEVICE]: "Allow multiple devices simultaneously",
	[ETranslation.USER_MODIFY_USER]: "Modify user",
	[ETranslation.USER_USERNAME]: "Username",
	[ETranslation.USER_PASSWORD]: "Password",
	[ETranslation.USER_PASSWORD_AGAIN]: "Salasana again",
	[ETranslation.USER_COPY_OF_EMAIL]: "Copy of email",
	[ETranslation.USER_ADDITIONAL_DETAILS]: "Additional details",
	[ETranslation.USER_REFERENCE]: "Default reference text",
	[ETranslation.USER_BILLING_REFERENCE]: "Invoice additional information (your reference)",
	[ETranslation.USER_BCC_ADDRESSES]: "BCC Email addresses",
	[ETranslation.USER_ALLOW_LOGIN]: "Allow login",
	[ETranslation.USER_DISPLAY_TEST_LANGUAGES]: "Display test languages",
	[ETranslation.USER_DISPLAY_PRICES_AND_PAYMENTS]: "Display price and payments",
	[ETranslation.USER_ALLOW_FAVORITE_INTERPRETER]: "Allow favorite interpreters",
	[ETranslation.USER_ALLOW_FASTLANE]: "Allow fastlane",
	[ETranslation.USER_EMAIL_WHEN_RESERVATION_CONFIRMED]: "Email whenever reservation is confirmed",
	[ETranslation.USER_ALLOW_PHONE_CALLS]: "Allow phone calls",
	[ETranslation.USER_ALLOW_MULTIPLE_CLIENTS]: "Allow simultaneous use",
	[ETranslation.USER_ALLOW_VIDEO]: "Allow video",
	[ETranslation.USER_PRIVATE_USER]: "Private individual",
	[ETranslation.USER_ALLOW_LOGS]: "Allow logs",
	[ETranslation.USER_BILLING_METHOD]: "Billing method",
	[ETranslation.USER_CREDITCARD_BILLING]: "Credit card billing",
	[ETranslation.USER_SHOW_ALL_COMPANY_INTERPRETATIONS]: "Show all company interpretations",
	[ETranslation.USER_PHONE_CONFIRMED]: "Phone number confirmed",
	[ETranslation.USER_ALLOW_ON_SITE]: "Allow on-site interpretation",
	[ETranslation.USER_SHOW_COMPANY_USERS]: "Show company users",
	[ETranslation.USER_ALLOW_VOIP]: "Allow internet calls",
	[ETranslation.USER_ALLOW_MARKETING]: "Allow marketing",
	[ETranslation.USER_ALLOW_REPORTING]: "Allow reporting",
	[ETranslation.USER_ALLOW_AUTOMATIC_RESERVATION_PROCESSING]: "Allow automatic reservation processing",
	[ETranslation.USER_SHOW_ALL_GROUP_INTERPRETATIONS]: "Show all group interpretations",
	[ETranslation.USER_LOCATION]: "Location",
	[ETranslation.USER_EMAIL_BCC]: "BCC email",
	[ETranslation.USER_ALTERNATE_PHONENUMBER]: "Alternate phone number",
	[ETranslation.USER_PAYMENT_BY_INVOICE]: "Invoice payment",
	[ETranslation.USER_PAYMENT_STRIPE]: "Stripe",
	[ETranslation.USER_PAYMENT_NO_CHARGE]: "No charge",
	[ETranslation.USER_PAYMENT_ZETTLE]: "Zettle",
	[ETranslation.USER_STATUS_OF_INTERPRETER]: "Worker status",
	[ETranslation.USER_CONTRACT]: "Contract",
	[ETranslation.USER_CONTRACT_NOT_MADE]: "Not made",
	[ETranslation.USER_CONTRACT_SEND]: "Sent",
	[ETranslation.USER_CONTRACT_COMPLETED]: "Completed",
	[ETranslation.USER_AUTOMATIC_PAYMENT]: "Automatic payment",
	[ETranslation.USER_ALLOW_AUTOMATIC_PAYMENT]: "Allow Automatic payment",
	[ETranslation.USER_SHOW_PAYMENT]: "Show payments",
	[ETranslation.USER_MANAGE_ORGANIZATION_USERS]: "Manage organization users",
	[ETranslation.USER_SHOW_ORGANIZATION_INTERPRETATIONS]: "Show organization interpretations",
	[ETranslation.USER_ALLOW_AUTOMATIC_OFFER_BOOKINGS]: "Allow automatic offer bookings",
	[ETranslation.USER_ALLOW_JOB_OFFERS]: "Allow job offers",
	[ETranslation.USER_NETVISOR_DIMENSION_NAME]: "Products cost center",
	[ETranslation.USER_NETVISOR_PRODUCT_IDENTIFIER]: "Product code",
	[ETranslation.USER_NETVISOR_PRODUCT_NAME]: "Product name",
	[ETranslation.USER_SWIFT]: "Swift",
	[ETranslation.USER_HIDE_INTERPRETER_FEE]: "Hide customer fee",
	[ETranslation.USER_TRAINED_INTERPRETER]: "Trained interpreter",
	[ETranslation.USER_LEGAL_INTERPRETER_EDIT_COPY]: "Legal interpreter (WARNING! NEEDS TO BE CONFIRMED)",
	[ETranslation.USER_LEGAL_INTERPRETER]: "Legal interpreter",
	[ETranslation.USER_SALARY_PAYMENT_RULE]: "Payment Rule",
	[ETranslation.USER_SALARY_IMMEDIATELY]: "Immediately upon Payment",
	[ETranslation.USER_SALARY_FIRST_DAY_OF_THE_MONTH]: "First Day of the Month",
	[ETranslation.USER_SALARY_MIDDLE_OF_MONTH]: "14th Day of the Month",
	[ETranslation.USER_SALARY_LAST_DAY_OF_THE_MONTH]: "Last Day of the Month",
	[ETranslation.USER_ACCORDION_BASIC]: "Basic Information",
	[ETranslation.USER_ACCORDION_ACCESS]: "Accesses",
	[ETranslation.USER_ACCORDION_LOCATION]: "Address Information",
	[ETranslation.USER_ACCORDION_LANGUAGES]: "Languages",
	[ETranslation.USER_ACCORDION_FAVOURITES]: "Favorite Interpreters",
	[ETranslation.USER_ACCORDION_BLOCKED]: "Blocked Interpreters",
	[ETranslation.USER_ACCORDION_NETVISOR]: "Netvisor",
	[ETranslation.USER_ADD_FAVOURITE_INTERPRETER]: "Add a favourite interpreter",
	[ETranslation.USER_SHOW_COMMENTS]: "Show comments",
	[ETranslation.USER_COMMENTS]: "Comments",
	[ETranslation.USER_INTERPRETER]: "Worker information",
	[ETranslation.USER_CUSTOMER_FEES]: "Customer fee settings",
	[ETranslation.USER_LOGOUT]: "Logout",
	[ETranslation.USER_RESET_AND_SEND_PASSWORD]: "Reset and send password",
	[ETranslation.USER_NEW_PASSWORD_IS]: "New password is",
	[ETranslation.USER_ALLOWED_TYPES]: "Allowed interpretation types",
	[ETranslation.USER_USER_LEVEL]: "Tarjoustaso",
};

const commonTranslations = {
	[ETranslation.COMMON_FILTER_BY_NAME]: "Filter by Name",
	[ETranslation.COMMON_BACK]: "Back",
	[ETranslation.COMMON_SENT]: "Sent",
	[ETranslation.COMMON_TIMESPAN]: "Timespan",
	[ETranslation.COMMON_RESET]: "Reset",
	[ETranslation.COMMON_NAME]: "Name",
	[ETranslation.COMMON_ADD_NEW]: "Add New",
	[ETranslation.COMMON_EDIT]: "Edit",
	[ETranslation.COMMON_BOOKING_IN_MINUTES]: "Booking in minutes",
	[ETranslation.COMMON_DELETE]: "Delete",
	[ETranslation.COMMON_PCS]: "pcs",
	[ETranslation.COMMON_RECORDING]: "Recording",
	[ETranslation.COMMON_TRANSLATION]: "Translation",
	[ETranslation.COMMON_ATTACHMENT]: "Attachments",
	[ETranslation.COMMON_AUDIO]: "Audio",
	[ETranslation.COMMON_TEXT]: "Text",
	[ETranslation.COMMON_FILE]: "File",
	[ETranslation.COMMON_SOURCE]: "Source",
	[ETranslation.COMMON_TARGET]: "Target",
	[ETranslation.COMMON_LINK]: "Link",
	[ETranslation.COMMON_SEARCH]: "Search",
	[ETranslation.COMMON_CLEAR]: "Clear",
	[ETranslation.COMMON_MONDAY]: "Monday",
	[ETranslation.COMMON_TUESDAY]: "Tuesday",
	[ETranslation.COMMON_WEDNESDAY]: "Wednesday",
	[ETranslation.COMMON_THURSDAY]: "Thursday",
	[ETranslation.COMMON_FRIDAY]: "Friday",
	[ETranslation.COMMON_SATURDAY]: "Saturday",
	[ETranslation.COMMON_SUNDAY]: "Sunday",
	[ETranslation.COMMON_YES]: "Yes",
	[ETranslation.COMMON_NO]: "No",
	[ETranslation.COMMON_RETURN]: "Return",
	[ETranslation.COMMON_EMAIL]: "Email",
	[ETranslation.COMMON_GENDER]: "Gender",
	[ETranslation.COMMON_ALLOWED]: "Allowed",
	[ETranslation.COMMON_NOT_ALLOWED]: "Not allowed",
	[ETranslation.COMMON_PHONENUMBER]: "Phone number",
	[ETranslation.COMMON_ALLOW]: "Allow",
	[ETranslation.COMMON_END_DATE]: "End date",
	[ETranslation.COMMON_START_DATE]: "Start date",
	[ETranslation.COMMON_END_TIME]: "End time",
	[ETranslation.COMMON_DOESNT_MATTER]: "Doesn't matter",
	[ETranslation.COMMON_START_TIME]: "Start time",
	[ETranslation.COMMON_DURATION]: "Duration",
	[ETranslation.COMMON_INFO_TO_BOTH_PARTIES]: "Additional details to both parties",
	[ETranslation.COMMON_SEND]: "Send",
	[ETranslation.COMMON_SHOW]: "Show",
	[ETranslation.COMMON_CATEGORY]: "Kategoria",
	[ETranslation.COMMON_GROUPS]: "Groups",
	[ETranslation.COMMON_FIRST_NAME]: "First name",
	[ETranslation.COMMON_LAST_NAME]: "Last name",
	[ETranslation.COMMON_STATUS]: "Status",
	[ETranslation.COMMON_CANCEL]: "Cancel",
	[ETranslation.COMMON_SUMMARY]: "Summary",
	[ETranslation.COMMON_SAVE]: "Save",
	[ETranslation.COMMON_USERS]: "Users",
	[ETranslation.COMMON_USER]: "User",
	[ETranslation.COMMON_CREATED]: "Created",
	[ETranslation.COMMON_LANGUAGE]: "Language",
	[ETranslation.COMMON_LANGUAGES]: "Languages",
	[ETranslation.COMMON_COMMENTS]: "Comments",
	[ETranslation.COMMON_TYPE]: "Type",
	[ETranslation.COMMON_ADDITIONAL_INFO]: "Additional info",
	[ETranslation.COMMON_FROM_LANGUAGE]: "From language",
	[ETranslation.COMMON_TO_LANGUAGE]: "To language",
	[ETranslation.COMMON_INTERPRETATION_DONE]: "Interpretation done",
	[ETranslation.COMMON_INTERPRETATIONS]: "Interpretations",
	[ETranslation.COMMON_ORDERS]: "Orders",
	[ETranslation.COMMON_UNKOWN]: "Unknown",
	[ETranslation.COMMON_GENDER_MALE]: "Male",
	[ETranslation.COMMON_GENDER_FEMALE]: "Female",
	[ETranslation.COMMON_ZIP_AND_LOCATION]: "Zip and location",
	[ETranslation.COMMON_ZIP]: "Zip",
	[ETranslation.COMMON_LOCATION]: "Location",
	[ETranslation.COMMON_COUNTRY]: "Country",
	[ETranslation.COMMON_ADDRESS]: "Address",
	[ETranslation.COMMON_CUSTOMER_ORGANIZATION_GROUP]: "Customer organization group",
	[ETranslation.COMMON_CUSTOMER_ORGANIZATION]: "Customer organization",
	[ETranslation.COMMON_ORGANIZATION_GROUP]: "Organization group",
	[ETranslation.COMMON_ORGANIZATION]: "Organization",
	[ETranslation.COMMON_CREATION]: "Creation Date",
	[ETranslation.COMMON_DURATION_IN_MINUTES]: "Duration in minutes",
	[ETranslation.COMMON_UPDATED]: "Updated",
	[ETranslation.COMMON_OTHER]: "Other",
	[ETranslation.COMMON_OTHERS]: "Others",
	[ETranslation.COMMON_IN_VAT_REGISTRY]: "In VAT registry",
	[ETranslation.COMMON_COMPANY_BUSINESSID]: "Company business ID",
	[ETranslation.COMMON_NOTES]: "Notes",
	[ETranslation.COMMON_ADMIN_NOTES]: "Admin notes",
	[ETranslation.COMMON_NEW]: "New",
	[ETranslation.COMMON_OPEN]: "Open",
	[ETranslation.COMMON_ACCEPTED]: "Accepted",
	[ETranslation.COMMON_REJECTED]: "Rejected",
	[ETranslation.COMMON_BUSY]: "Busy",
	[ETranslation.COMMON_CLOSED]: "Closed",
	[ETranslation.COMMON_CONFIRMED]: "Confirmed",
	[ETranslation.COMMON_CANCELLED]: "Cancelled",
	[ETranslation.COMMON_COMPLETED]: "Completed",
	[ETranslation.COMMON_CALLED]: "Called",
	[ETranslation.COMMON_INSTANT]: "Instant",
	[ETranslation.COMMON_BOOKING]: "Booking",
	[ETranslation.COMMON_ATTENDANCE]: "Attendance",
	[ETranslation.COMMON_VAT]: "VAT %",
	[ETranslation.COMMON_PAYMENT_METHOD]: "Payment method",
	[ETranslation.COMMON_COMPLETE]: "Complete",
	[ETranslation.COMMON_IN_PROGRESS]: "In progress",
	[ETranslation.COMMON_CUSTOMER]: "Customer",
	[ETranslation.COMMON_INTERPRETER]: "Interpreter",
	[ETranslation.COMMON_NOW]: "Now",
	[ETranslation.COMMON_DESCRIPTION]: "Description",
	[ETranslation.COMMON_ORGANIZATIONS]: "Organizations",
	[ETranslation.COMMON_SECURITY_CLEARED]: "Security cleared",
	[ETranslation.COMMON_AVAILABLE]: "Available",
	[ETranslation.COMMON_NOT_AVAILABLE]: "Not available",
	[ETranslation.COMMON_RESERVED]: "Reserved",
	[ETranslation.COMMON_OFFLINE]: "Offline",
	[ETranslation.COMMON_BLOCKED_INTERPRETERS]: "Blocked interpreters",
	[ETranslation.COMMON_SOCIAL_SECURITY_NUMBER]: "Social security number",
	[ETranslation.COMMON_IBAN]: "IBAN",
	[ETranslation.COMMON_ALL]: "All",
	[ETranslation.COMMON_CHOOSE_LANGUAGES]: "Choose languages",
	[ETranslation.COMMON_CHOOSE_LANGUAGE]: "Choose öanguage",
	[ETranslation.COMMON_PUBLISHED]: "Published",
	[ETranslation.COMMON_DRAFT]: "Draft",
	[ETranslation.COMMON_INSTANT_WITHOUT_VAT]: "Instant: VAT-free customer charge € / min",
	[ETranslation.COMMON_BOOKING_WITHOUT_VAT]: "Booking: VAT-free customer charge € / min",
	[ETranslation.COMMON_ON_SITE_WITHOUT_VAT]: "On site: VAT-free price € / min",
	[ETranslation.COMMON_EXPORT]: "Export",
	[ETranslation.COMMON_TAGS]: "Tags",
	[ETranslation.COMMON_NETVISOR]: "Netvisor",
	[ETranslation.COMMON_ORDER_NUMBER]: "Order  number",
	[ETranslation.COMMON_SITE]: "Site",
	[ETranslation.COMMON_PAYMENTS]: "Payment",
	[ETranslation.COMMON_PRICE]: "Price",
	[ETranslation.COMMON_FUNCTIONS]: "Functions",
	[ETranslation.COMMON_MIN]: "min.",
	[ETranslation.COMMON_OPEN_INFO]: "Open details",
	[ETranslation.COMMON_DATE]: "Date",
	[ETranslation.COMMON_APPOINTMENT]: "Appointment",
	[ETranslation.COMMON_INFO_TO_INTERPRETER]: "Additional details to the interpreter",
	[ETranslation.COMMON_INFORMATION]: "Information",
	[ETranslation.COMMON_CONNECTION_DETAIL]: "Contact method",
	[ETranslation.COMMON_INTERPRETATION_INFORMATION]: "Details about information",
	[ETranslation.COMMON_COST_CENTERS]: "Cost centers",
	[ETranslation.COMMON_CUSTOMER_REFERENCE]: "Customers reference",
	[ETranslation.COMMON_CUSTOMER_REFERENCE_2]: "Customers reference 2",
	[ETranslation.COMMON_REVIEW_FROM_INTERPRETER]: "Interpreters rating of the interpretation",
	[ETranslation.COMMON_REVIEW_FROM_CUSTOMER]: "Customers ratings of th einterpretation",
	[ETranslation.COMMON_ADMIN]: "Admin",
	[ETranslation.COMMON_ARE_YOU_SURE]: "Are you sure",
	[ETranslation.COMMON_DONE]: "Done",
	[ETranslation.COMMON_CLOSE]: "Close",
	[ETranslation.COMMON_REVIEWS]: "Reviews",
	[ETranslation.COMMON_PAYMENT_INFORMATION]: "Payment information",
	[ETranslation.COMMON_USER_AGENT]: "User agent",
	[ETranslation.COMMON_ACTION]: "Action",
	[ETranslation.COMMON_ACTIONS]: "Actions",
	[ETranslation.COMMON_EMAIL_SENT]: "Sent",
	[ETranslation.COMMON_EMAIL_SENDER]: "Sender",
	[ETranslation.COMMON_EMAIL_RECIPIENT]: "Recipient",
	[ETranslation.COMMON_EMAIL_TITLE]: "Title",
	[ETranslation.COMMON_EMAIL_CONTENT]: "Body",
	[ETranslation.COMMON_EMAILS]: "Emails",
	[ETranslation.COMMON_ROLE]: "Role",
	[ETranslation.COMMON_MOVE_TO_NETVISOR]: "Move to netvisor",
	[ETranslation.COMMON_BILL_SENT_DATE]: "Bill sent date",
	[ETranslation.COMMON_LOST_MINUTES]: "Lost minutes",
	[ETranslation.COMMON_FEE_TYPE]: "Palkkion maksutapa",
	[ETranslation.COMMON_REFERENCE]: "Reference",
	[ETranslation.COMMON_BANKING_REFERENCE]: "Banktransfer reference",
	[ETranslation.COMMON_SALARY_PER_HOUR]: "Rate per hour",
	[ETranslation.COMMON_SUBTYPES]: "Profession",
	[ETranslation.COMMON_PROJECT]: "Project",
	[ETranslation.COMMON_COPY]: "Copy",
	[ETranslation.COMMON_COPY_CONFIRM_TEXT]: "Are you sure you want to copy?",
};

const categoryTranslations = {
	[ETranslation.CATEGORY_EDIT_CATEGORY]: "Edit category",
	[ETranslation.CATEGORY_DELETE_CATEGORY]: "Delete category?",
	[ETranslation.CATEGORY_ADD_CATEGORY]: "Add category",
};

const attributeTranslations = {
	[ETranslation.ATTRIBUTES_NO_ATTRIBUTES]: "No attributes",
	[ETranslation.ATTRIBUTES_EDIT_ATTRIBUTE]: "Edit attribute",
	[ETranslation.ATTRIBUTES_DELETE_ATTRIBUTE]: "Delete attribute?",
	[ETranslation.ATTRIBUTES_ADD_ATTRIBUTE]: "Add attribute",
};

const salaryTranslations = {
	[ETranslation.SALARYINFO_NO_SALARIES]: "No salaries",
};

const Base64DropzoneTranslations = {
	[ETranslation.BASE64_DROPZONE_TOO_LARGE]: `File is too large, maximum size is ${MAX_SIZE}kb`,
	[ETranslation.BASE64_DROPZONE_NOT_IMAGE]: "File is not an image",
};

const customDropZoneTranslations = {
	[ETranslation.DROPZONE_FILE_TOO_LARGE]: "File is too large, maximum size",
	[ETranslation.DROPZONE_FILE_TYPE_INVALID]: "Check file type.",
	[ETranslation.DROPZONE_FILE_DRAG_DROP]: "Drag and drop file",
	[ETranslation.DROPZONE_FILE_CLICK_OR_DROP]: "Click here or drop file",
	[ETranslation.DROPZONE_ATTACHMENT_UPDATE_FAILED]: "Attachment update failed.",
	[ETranslation.DROPZONE_ATTACHMENT_UPLOAD_FAILED]: "Attachment upload failed.",
	[ETranslation.DROPZONE_ATTACHMENT_CREATION_FAILED]: "Attachment creation failed.",
};

const deleteModalTranslations = {
	[ETranslation.DELETEMODAL_CONFIRMATION]: "Are you sure you want to delete?",
	[ETranslation.DELETEMODAL_DELETE]: "Delete",
	[ETranslation.DELETEMODAL_CANCEL]: "Cancel",
};

const infoContactRequestTranslations = {
	[ETranslation.CONTACT_REQUEST_NO_REQUESTS]: "No contact requests.",
	[ETranslation.CONTACT_REQUEST_SUBJECT]: "Subject",
	[ETranslation.CONTACT_REQUEST_MUNICIPALITY]: "Municipality",
	[ETranslation.CONTACT_REQUEST_NEED_INTERPRETER]: "I need an interpreter",
	[ETranslation.CONTACT_REQUEST_TO_LANGUAGE]: "Interpreted language",
	[ETranslation.CONTACT_REQUEST_FILTER]: "Filter by subject",
	[ETranslation.CONTACT_REQUEST_OTHER_SUBJECT]: "Other subject",
	[ETranslation.CONTACT_REQUEST_NOTES]: "Comments (not shown to the customer)",
	[ETranslation.CONTACT_REQUEST_CANCELED_DATE]: "Canceled date",
};

const messageTranslations = {
	[ETranslation.MESSAGE_MESSAGE_TRANSLATION]: "Message translation",
	[ETranslation.MESSAGE_RECORDINGBANK]: "Recording bank",
	[ETranslation.MESSAGE_MESSAGES]: "Messages",
	[ETranslation.MESSAGE_ADD_MESSAGE]: "Add message",
	[ETranslation.MESSAGE_NO_RECORDINGS_OR_TRANSLATIONS]: "No recordings or translations",
	[ETranslation.MESSAGE_CONTENT]: "Message content:",
	[ETranslation.MESSAGE_COPY_FAILED]: "Copy failed.",
};

const newsItemTranslations = {
	[ETranslation.NEWS_NO_NEWS]: "No news",
	[ETranslation.NEWS_HEADLINE]: "Headline",
	[ETranslation.NEWS_PUBLISH_DATE]: "Publish date",
	[ETranslation.NEWS_SHOWN_TO_LANGUAGES]: "Shown to languages",
	[ETranslation.NEWS_SHOWN_IN_PLACES]: "Shown in places",
	[ETranslation.NEWS_REVIEW]: "Review",
	[ETranslation.NEWS_NOT_PUBLISHED]: "Not published",
	[ETranslation.NEWS_ISREVIEW]: "Review",
	[ETranslation.NEWS_ISNOTREVIEW]: "Not a review",
	[ETranslation.NEWS_IMAGE]: "Image",
	[ETranslation.NEWS_IN_BRIEF]: "In Brief",
	[ETranslation.NEWS_CONTENT]: "Content",
	[ETranslation.NEWS_PUBLISH_TIME]: "Publish time",
	[ETranslation.NEWS_SHOW_PUBLISH_DATE]: "Show publish date",
	[ETranslation.NEWS_RATING]: "Rating",
	[ETranslation.NEWS_REVIEW_PUBLISH_TEXT]:
		"Do you want to make a news item out of this review? (Note: this review will be publicly visible!)",
	[ETranslation.NEWS_REVIEW_PUBLISH_DONE]: "The review has now been turned into a news item.",
	[ETranslation.NEWS_MAKE_REVIEW]: "Make a news item from the review.",
	[ETranslation.NEWS_CUSTOMER_REVIEW]: "Customer review",
	[ETranslation.NEWS_INTERPRETER_REVIEW]: "Interpreter review",
	[ETranslation.NEWS_MAKE_REVIEW_BUTTON]: "Make review",
	[ETranslation.NEWS_NO_REVIEW]: "There is no review for this interpretation",
};

const organizationGroupTranslations = {
	[ETranslation.ORGANIZATION_GROUP_NO_GROUPS]: "No groups",
	[ETranslation.ORGANIZATION_GROUP_ORDER_ID]: "Order ID",
	[ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_LABEL]: "Price Seasons",
	[ETranslation.ORGANIZATION_GROUP_PRICE_SEASONS_IDS_PLACEHOLDER]: "Select price seasons",
};

const priceSeasonsTranslations = {
	[ETranslation.PRICE_SEASON_NO_PRICE_SEASONS]: "No price seasons.",
	[ETranslation.PRICE_SEASON_PERIOD]: "Period",
	[ETranslation.PRICE_SEASON_PERIOD_2]: "Period:",
	[ETranslation.PRICE_SEASON_ACTIVE]: "Active",
	[ETranslation.PRICE_SEASON_PRICERULES_TITLE]: "Price rules",
	[ETranslation.PRICE_SEASON_NO_PRICE_RULES]: "No price rules",
	[ETranslation.PRICE_SEASON_PRICES]: "Prices",
	[ETranslation.PRICE_SEASON_TAGS_DAYRULES]: "Tags / day rules",
	[ETranslation.PRICE_SEASON_CHARGE]: "Amount",
	[ETranslation.PRICE_SEASON_MIN_BILLING_PERIOD]: "Min. Billing period (min)",
	[ETranslation.PRICE_SEASON_MIN_ROUNDING_PERIOD]: "Rounding (min)",
	[ETranslation.PRICE_SEASON_TERMS_OF_CANCEL]: "Cancellation terms",
	[ETranslation.PRICE_SEASON_CHOOSE_TERMS_OF_CANCEL]: "Choose cancellation terms",
	[ETranslation.PRICE_SEASON_MIN_DURATION]: "Min. Duration (min)",
	[ETranslation.PRICE_SEASON_MAX_DURATION]: "Max. Duration (min)",
	[ETranslation.PRICE_SEASON_MINUTES_BEFORE_START]: "Minutes before start",
	[ETranslation.PRICE_SEASON_CHOOSE_TYPE]: "Choose type",
	[ETranslation.PRICE_SEASON_DAYRULES]: "Day rules",
	[ETranslation.PRICE_SEASON_EDIT_PRICERULES]: "Edit price rule",
	[ETranslation.PRICE_SEASON_ADD_PRICERULE]: "Add price rule",
};

const siteTranslations = {
	[ETranslation.SITE_NO_SITES]: "No sites",
	[ETranslation.SITE_PREFIX]: "Prefix",
	[ETranslation.SITE_TIMEZONE]: "Timezone",
	[ETranslation.SITE_CHOOSE_TIMEZONE]: "Choose timezone",
	[ETranslation.SITE_ACTIVE]: "Active",
	[ETranslation.SITE_TARGET_MARGIN]: "Target margin %",
	[ETranslation.SITE_MIN_REWARD]: "Min. Reward per minute €",
	[ETranslation.SITE_MAX_REWARD]: "Max. Reward per minute €",
	[ETranslation.SITE_APPOINTMENT_REWARDS]: "Appointment rewards",
	[ETranslation.SITE_TYPES]: "Types",
	[ETranslation.SITE_ADDRESS_TITLE]: "Contact information",
	[ETranslation.SITE_ADDRESS]: "Street address",
	[ETranslation.SITE_ZIP]: "Zip",
	[ETranslation.SITE_CITY]: "City",
	[ETranslation.SITE_PHONE_NUMBER]: "Phone number",
	[ETranslation.SITE_ORGANIZATION_NAME]: "Company name",
	[ETranslation.SITE_BUSINESS_ID]: "Business ID",
};

const textMessageTranslations = {
	[ETranslation.TEXTMESSAGE_CONFIRM_SEND]: "Confirm send",
	[ETranslation.TEXTMESSAGE_ARE_YOU_SURE_SEND]: "Are you sure you want to send?",
	[ETranslation.TEXTMESSAGE_NO_MESSAGES]: "No messages",
	[ETranslation.TEXTMESSAGE_TRANSMISSION]: "Transmission",
	[ETranslation.TEXTMESSAGE_MESSAGE_TEXT]: "Message text",
	[ETranslation.TEXTMESSAGE_RECEIVER_NUMBER]: "Receiver numbers",
};

const pagesFileUploadTranslations = {
	[ETranslation.PAGES_FILEUPLOAD_FILES]: "Files",
	[ETranslation.PAGES_FILEUPLOAD_UPLOAD_DEMO]: "Uploaddemo",
	[ETranslation.PAGES_FILEUPLOAD_DROPZONE_ALERT]: "Dropzone is not ready implementation.",
};

const pagesContactRequestTranslations = {
	[ETranslation.PAGES_CONTACTREQUEST_EDIT_TITLE]: "Contact request",
	[ETranslation.PAGES_CONTACTREQUEST_EDIT_DELETE]: "Are you sure you want to delete the contact request?",
};

const pagesMessagesTranslations = {
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_ORDERED_WORKS]: "Choose ordered works",
	[ETranslation.PAGES_MESSAGE_EDIT_RECORDING]: "Recording",
	[ETranslation.PAGES_MESSAGE_EDIT_TRANSLATION]: "Translation",
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_RECORDING_TRANSLATION]: "Choose recording or translation",
	[ETranslation.PAGES_MESSAGE_EDIT_CHOOSE_SOURCE_TYPE]: "Choose source material type",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_FILE]: "Add file",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_TEXT]: "Add text",
	[ETranslation.PAGES_MESSAGE_EDIT_ADD_MORE_INFORMATION]: "Add additional information (optional)",
	[ETranslation.PAGES_MESSAGE_EDIT_ALLOW_SHARING]: "Allow sharing",
	[ETranslation.PAGES_MESSAGE_GROUP_EDIT_TITLE]: "Recording / translation / attachment",
	[ETranslation.PAGES_MESSAGE_GROUP_EDIT_DELETE_CONFIRM]:
		"Are you sure you want to delete the message interpretation / recording bank?",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE]: "Delete message",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_DELETE_MESSAGE_CONFIRM]: "Are you sure you want to delete the message?",
	[ETranslation.PAGES_MESSAGE_SHOW_PAGE_ANWSER]: "Reply to message",
};

const pagesNewsTranslations = {
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_TITLE]: "Instructions",
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_1]:
		"The total size of images added to the content can be a maximum of 500 kilobytes. Resize the images to ensure fast loading.",
	[ETranslation.PAGES_NEWS_INSTRUCTIONS_CONTENT_2]:
		"You can make a news item appear at the top of the list by setting the publication date in the future and hiding the publication date.",
	[ETranslation.PAGES_NEWS_DELETE_CONFIRM]: "Are you sure you want to delete the news?",
	[ETranslation.PAGES_ADD_NEWS]: "Add News",
};

const pagesOrganizationsTranslations = {
	[ETranslation.PAGES_ORGANIZATION_INFO]: "Here are instructions for the organization group.",
	[ETranslation.PAGES_ORGANIZATION_DELETE_CONFIRM]: "Are you sure you want to delete the group?",
};

const organizationCompaniesTranslations = {
	[ETranslation.PAGES_ORGANIZATION_COMPANY_INFO]: "Here is the information for companies.",
	[ETranslation.PAGES_ORGANIZATION_COMPANY_DELETE_CONFIRM]: "Are you sure you want to delete the company?",
	[ETranslation.ORGANIZATION_COMPANY_NO_COMPANIES]: "No companies",
	[ETranslation.ORGANIZATION_COMPANY_FEE_INSTANT_INTERPRETATION]: "Customer fee: instant interpretation",
	[ETranslation.ORGANIZATION_COMPANY_FEE_APPOINTMENT]: "Customer fee: appointment",
	[ETranslation.ORGANIZATION_COMPANY_ORGANIZATIONGROUP]: "Group",
	[ETranslation.ORGANIZATION_COMPANY_INDUSTRY_CODE]: "Industry code",
	[ETranslation.ORGANIZATION_COMPANY_INDUSTRY]: "Industry",
	[ETranslation.ORGANIZATION_COMPANY_TIETOPALVELU]: "Company search: https://tietopalvelu.ytj.fi",
	[ETranslation.ORGANIZATION_COMPANY_SEARCH]: "Search companies:",
	[ETranslation.ORGANIZATION_COMPANY_SEARCH_COMPANIES]:
		"You can search for information about all companies with a Business ID by name or Business ID.",
	[ETranslation.ORGANIZATION_COMPANY_WEBSITE]: "Company website",
	[ETranslation.ORGANIZATION_COMPANY_BOOKINGEMAIL]: "Booking confirmation email",
	[ETranslation.ORGANIZATION_DURATIONS]: "Company interpretation durations",
	[ETranslation.ORGANIZATION_COMPANY_DURATIONS]: "Company interpretation duration",
	[ETranslation.ORGANIZATION_DEFAULT_DURATIONS]: "Default interpretation duration",
	[ETranslation.ORGANIZATION_COMPANY_DEFAULT_INTERPRETATION_TYPE]: "Default interpretation Type",
	[ETranslation.ORGANIZATION_CUSTOMER_APPROVAL_REQUIRED]: "Customer approval required",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_MANDATORY]: "Cost center selection mandatory",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_HIDDEN]: "Customer reference hidden",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY]: "Customer reference mandatory",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_MANDATORY_IN_REVIEW]:
		"Customer reference mandatory in review",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY]: "Customer reference 2 mandatory",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_2MANDATORY_IN_REVIEW]:
		"Customer reference 2 mandatory in review",
	[ETranslation.ORGANIZATION_COMPANY_ONLY_SECURITY_CLEARED_INTERPRETERS]: "Only security-cleared interpreters",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_ATTACHMENTS]: "Allow attachments",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_AUTO_REMINDERS]: "Allow automatic reminders (for unconfirmed bookings)",
	[ETranslation.ORGANIZATION_COMPANY_SHOW_LANGUAGE_AVAILABILITY]: "Show language availability",
	[ETranslation.ORGANIZATION_COMPANY_CONTACT_METHOD]: "Contact methods",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CUSTOMER]: "Interpreter makes the call",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CUSTOMER_CALL_TO_INTERPRETER]: "Customer makes the call",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONTACT_CALL_TO_INTERPRETER]:
		"Contact number makes the call to interpreter",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_INTERPRETER_CALL_TO_CONTACT]:
		"Interpreter makes the call to contact number",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_VIDEO_CONFERENCE]:
		"Video conference link (e.g., Google Meet, Zoom, Microsoft Teams)",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_MASKED_NUMBER]: "Allow number masking",
	[ETranslation.ORGANIZATION_COMPANY_ALLOW_CONNECTION_TYPE_SELECT_FOR_INSTANT]:
		"Allow connection type selection for instant interpretation",
	[ETranslation.ORGANIZATION_COMPANY_LOCATION_INFORMATION]: "Address information",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_INFORMATION]: "Price information",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_INSTANT]:
		"Instant interpretation: minimum billing period in minutes",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_INSTANT_EXTENDED]:
		"Instant interpretation over 30 min: price without VAT / min",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_BOOKING]:
		"Appointment: minimum billing period in minutes",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_BOOKING_EXTENDED]:
		"Appointment over 30 min: Price without VAT / min",
	[ETranslation.ORGANIZATION_COMPANY_MIN_BILLING_PERIOD_IN_MINUTES_FOR_ON_SITE]:
		"On-site: minimum billing period in minutes",
	[ETranslation.ORGANIZATION_COMPANY_PRICE_WITHOUT_VAT_PER_MINUTE_FOR_ON_SITE_EXTENDED]:
		"On-site over 30 min: price without VAT / min",
	[ETranslation.ORGANIZATION_COMPANY_TERMS_OF_CANCEL_TYPE_BOOKING]: "Appointment: cancellation terms",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_TITLE]: "Customer reference title",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE_INFO_TEXT]: "Customer reference instructions",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_TITLE]: "Customer reference 2 title",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_REFERENCE2_INFO_TEXT]: "Customer reference 2 instructions",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_TITLE]: "Customer interpretation topic",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_INFO_TEXT]: "Customer interpretation instructions",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_TITLE]: "Customer message topic",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_NOTES_MESSAGE_INFO_TEXT]: "Customer message instructions",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_CHARGE_WITHOUT_VAT]: "Billing charge € (VAT 0)",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_REFERENCE]: "Invoice additional information (your reference)",
	[ETranslation.ORGANIZATION_COMPANY_REFERENCE]: "Default reference text",
	[ETranslation.ORGANIZATION_AGREEMENT_IDENTIFIER]: "Laskun lisätiedot (sopimustunnus) TODO Translation",
	[ETranslation.ORGANIZATION_COMPANY_BILLING_PAYMENT_TERM]: "Payment terms",
	[ETranslation.ORGANIZATION_COMPANY_INTERPRETER_FEE_SETTINGS]: "Interpreter customer fee settings",
	[ETranslation.ORGANIZATION_COMPANY_CUSTOMER_CODE]: "Customer Code",
	[ETranslation.ORGANIZATION_COMPANY_EINVOICE_ADDRESS]: "E-Invoice address",
	[ETranslation.ORGANIZATION_COMPANY_OPERATOR_CODE]: "Operator code",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_7]: "7 days",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_14]: "14 days",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_21]: "21 days",
	[ETranslation.ORGANIZATION_COMPANY_DAYS_30]: "30 days",
	[ETranslation.ORGANIZATION_COMPANY_LAST_DAY_OF_MONTH]: "Last day of the month",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_NAME]: "Cost center name",
	[ETranslation.ORGANIZATION_COMPANY_COST_CENTER_DESCRIPTION]: "Cost center description",
};

const pagesPriceSeasonsTranslations = {
	[ETranslation.PAGES_PRICE_SEASON_TITLE]: "Price season",
	[ETranslation.PAGES_PRICE_SEASON_INFO]: "Here are instructions for the text",
	[ETranslation.PAGES_PRICE_SEASON_CONFIRM_DELETE]: "Are you sure you want to delete the price season?",
	[ETranslation.PAGES_PRICE_SEASONS_ADD_NEW]: "New price s eason",
};

const pagesPriceRulesTranslations = {
	[ETranslation.PAGES_PRICE_RULES_TITLE]: "Price rule",
	[ETranslation.PAGES_PRICE_RULES_INFO_1]:
		"EXAMPLE 1: If the minimum billing time is 15 minutes, reservation is 60 minutes, and duration is 46 minutes. The customer will be billed for 60 minutes if there is no separate rounding rule.",
	[ETranslation.PAGES_PRICE_RULES_INFO_2]:
		"EXAMPLE 2: If the minimum billing time is 15 minutes, rounding is 30 minutes, reservation is 60 minutes, and duration is 61 minutes. The customer will be billed for 90 minutes. If the duration is 55 minutes, the customer will be billed for 60 minutes. Rounding is used to round up the time exceeding the reservation.",
	[ETranslation.PAGES_PRICE_RULE_DELETE_CONFIRM]: "Are you sure you want to delete the price rule?",
	[ETranslation.PAGES_PRICE_RULES_LIST_TITLE]: "Price rules",
	[ETranslation.PAGES_PRICE_RULES_LIST_ADD_NEW]: "New price rule",
	[ETranslation.PAGES_PRICE_RULES_EDIT_TITLE]: "Price pule",
	[ETranslation.PAGES_PRICE_RULES_EDIT_INFO_1]:
		"EXAMPLE 1: If the minimum billing time is 15 minutes, reservation is 60 minutes, and duration is 46 minutes. The customer will be billed for 60 minutes if there is no separate rounding rule.",
	[ETranslation.PAGES_PRICE_RULES_EDIT_INFO_2]:
		"EXAMPLE 2: If the minimum billing time is 15 minutes, rounding is 30 minutes, reservation is 60 minutes, and duration is 61 minutes. The customer will be billed for 90 minutes. If the duration is 55 minutes, the customer will be billed for 60 minutes. Rounding is used to round up the time exceeding the reservation.",
	[ETranslation.PAGES_PRICE_RULES_EDIT_DELETE_CONFIRM]: "Are you sure you want to delete the price rule?",
};

const pagesSiteTranslations = {
	[ETranslation.PAGES_SITE_EDIT_INFO]: "Here is the creation/edit view of the site with instructions.",
	[ETranslation.PAGES_SITE_EDIT_DELETE_CONFIRM]: "Are you sure you want to delete the site?",
	[ETranslation.PAGES_SITES_LIST_ADD_NEW]: "New site",
};

const pagesTextMessagesTranslations = {
	[ETranslation.PAGES_TEXTMESSAGE_TITLE]: "Text message",
	[ETranslation.PAGES_TEXTMESSAGE_INFO]:
		"Receiver numbers should be entered separated by commas or spaces. A single number should not contain spaces or special characters. Only the + sign at the beginning of the number and numbers are allowed.",
	[ETranslation.PAGES_TEXTMESSAGE_DELETE_CONFIRM]: "Are you sure you want to delete the message?",
	[ETranslation.PAGES_TEXTMESSAGES_LIST_TITLE]: "Messages",
	[ETranslation.PAGES_TEXTMESSAGES_LIST_ADD_NEW]: "New message",
};

const pagesCustomerReportsTranslations = {
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_ORGANIZATION]: "Choose organization",
	[ETranslation.PAGES_CUSTOMER_REPORTS_ALL_ORGANIZATIONS]: "All organizations",
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_SUBSCRIBER]: "Choose Ssbscriber",
	[ETranslation.PAGES_CUSTOMER_REPORTS_ALL_SUBSCRIBERS]: "All subscribers",
	[ETranslation.PAGES_CUSTOMER_REPORTS_CHOOSE_CUSTOMER_ORGANIZATION]: "Choose only 1 customer organization",
	[ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_A]: "Subscriber A",
	[ETranslation.PAGES_CUSTOMER_REPORTS_SUBSCRIBER_B]: "Subscriber B",
};

const dataContactRequestTranslations = {
	[ETranslation.DATA_CONTACT_REQUEST_IMMIGRATION]: "Immigration issues",
	[ETranslation.DATA_CONTACT_REQUEST_PERMIT_MIGRI]: "Permit-matters (Migri)",
	[ETranslation.DATA_CONTACT_REQUEST_CLARIFICATION_AND_FORMS]: "Clarification of decisions and processes, forms",
	[ETranslation.DATA_CONTACT_REQUEST_STUDYING]: "Studying",
	[ETranslation.DATA_CONTACT_REQUEST_WORK]: "Job and job search",
	[ETranslation.DATA_CONTACT_REQUEST_CANCELED]: "Canceled",
};

const dataPriceSeasonsTranslations = {
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_MINUTE]: "Price per minute",
	[ETranslation.DATA_PRICE_SEASON_PRICE_PER_INTERPRETATION]: "Interpretation price",
	[ETranslation.DATA_PRICE_SEASON_PRICE_P_PER_INTERPRETATION]: "Interpretation percentage",
	[ETranslation.DATA_PRICE_SEASON_DISCOUNT_PER_INTERPRETATION]: "Discount price",
	[ETranslation.DATA_PRICE_SEASON_DISCOUNT_P_PER_INTERPRETATION]: "Discount percentage",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_48]: "48 hours before start time",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_24]: "24 hours before start time",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_12]: "12 hours before start time",
	[ETranslation.DATA_PRICE_SEASON_BEFORE_START_6]: "6 hours before start time",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_BUSINESS_DAY_AT_17]: "Previous business day by 17:00",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_15_30]: "Previous day by 15:30",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_16]: "Previous day by 16:00",
	[ETranslation.DATA_PRICE_SEASON_PREVIOUS_DAY_AT_12]: "Previous day by 12:00",
	[ETranslation.DATA_PRICE_SEASON_PUBLIC_HOLIDAY]: "Public holiday",
	[ETranslation.DATA_PRICE_SEASON_VIDEO]: "Video",
};

const storeTranslations = {
	[ETranslation.AUTH_ACTIONS_USER_NOT_IDENTIFIED]: "User not identified.",
	[ETranslation.REDUCER_ERROR]: "Error",
	[ETranslation.REDUCER_SEARCH_ERROR]: "Error! Search failed.",
	[ETranslation.REDUCER_SAVE_ERROR]: "Error! Save failed.",
};

const appTranslations = {
	[ETranslation.LOGIN]: "Log In",
	[ETranslation.LOGIN_SUCCESS]: "Login successful",
	[ETranslation.NO_ACCESS]: "No access.",
};

const logItemTranslations = {
	[ETranslation.PAGES_LOG_ITEM_EDIT_TITLE]: "Edit log",
	[ETranslation.PAGES_LOG_ITEM_EDIT_INFO]: "Edit info",
	[ETranslation.PAGES_LOG_ITEMS_LIST_ADD_NEW]: "Add log",
	[ETranslation.LOG_ITEM_LIST_NO_LOGS]: "No logs",
	[ETranslation.LOG_ITEM_LIST_BROWSER]: "Browser",
	[ETranslation.LOG_ITEM_LIST_CITY]: "City",
	[ETranslation.LOG_ITEM_LIST_COUNTRY]: "Country",
	[ETranslation.LOG_ITEM_LIST_EVENT]: "Event",
	[ETranslation.LOG_ITEM_LIST_FULLURL]: "URL",
	[ETranslation.LOG_ITEM_LIST_INTERPRETATION]: "Interpretation",
	[ETranslation.LOG_ITEM_LIST_IPADDRESS]: "IP Address",
	[ETranslation.LOG_ITEM_LIST_OPERATINGSYSTEM]: "Operating system",
	[ETranslation.LOG_ITEM_LIST_REFERER]: "Referer",
	[ETranslation.LOG_ITEM_LIST_REGION]: "Region",
	[ETranslation.LOG_ITEM_LIST_UPDATED]: "Updated",
	[ETranslation.LOG_ITEM_LIST_USERAGENT]: "User agent",
	[ETranslation.LOG_ITEM_LIST_SUMMARY]: "Summary",
	[ETranslation.LOG_ITEM_LIST_FILTER]: "Filter by user, summary, message, or admin notes",
};
const interpretationTranslations = {
	[ETranslation.INTERPRETATION_CUSTOMER_ESTIMATE]: "Estimate how long upou need the interpreter",
	[ETranslation.INTERPRETATION_GENDER]: "Interpreter's gender (Optional)",
	[ETranslation.INTERPRETATION_TAGS]: "Additional options",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES]: "Interpretation topic",
	[ETranslation.INTERPRETATION_CUSTOMER_COST_CENTER]: "Select cost center",
	[ETranslation.INTERPRETATION_AVAILABLE_INTERPRETERS]: "Available interpreters",
	[ETranslation.INTERPRETATION_VIDEO_URL]: "Meeting link",
	[ETranslation.INTERPRETATION_VIDEO_INFO]: "Additional meeting information",
	[ETranslation.INTERPRETATION_ON_SITE_INFO]: "Additional location information",
	[ETranslation.INTERPRETATION_ORDER_MULTIPLE]: "Order interpretations",
	[ETranslation.INTERPRETATION_NO_MATCH_CONNECTION_DETAIL]: "The customer's organization does not have this option",
	[ETranslation.INTERPRETATION_ADD_ALTERNATIVE]: "Add alternative",
	[ETranslation.INTERPRETATION_INTERPRETER_REVIEW]: "Interpreter review",
	[ETranslation.INTERPRETATION_INTERPRETER_RATING]: "Interpreter's rating for review",
	[ETranslation.INTERPRETATION_CUSTOMER_REVIEW]: "Customer review",
	[ETranslation.INTERPRETATION_CUSTOMER_RATING]: "Customer's rating for review",
	[ETranslation.INTERPRETATION_INTERPRETER_NAME]: "Interpreter's name",
	[ETranslation.INTERPRETATION_SEARCH_ALL]: "Show all",
	[ETranslation.SEARCH_ALL_OCCURANCES]: "Search from user rows",
	[ETranslation.INTERPRETATION_AMOUNT_OF_INTERPRETATIONS]: "Amount of interpretations:",
	[ETranslation.INTERPRETATION_AVG_DURATION]: "Average duration of an interpretations with duration over 0min: ",
	[ETranslation.INTERPRETATION_CUSTOMER_NOTES_TEXT]: "Additional notes from the customer",
	[ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TITLE]: "Export to Youpret Pay",
	[ETranslation.INTERPRETATION_EXPORT_TO_YOUPRET_PAY_TEXT]: "Create payment",
	[ETranslation.INTERPRETATION_PAYMENT_DETAILS_TITLE]: "Payment details",
	[ETranslation.INTERPRETATION_PAYMENT_DETAILS_TEXT]: "Link to payment",
	[ETranslation.INTERPRETATION_PAYMENT_STATUS]: "Payment status",
	[ETranslation.INTERPRETATION_ADD_INTERPRETER]: "Add interpreter",
	[ETranslation.INTERPRETATION_SEND_AUTOMATIC_OFFERS]: "Send automatic job offers",
	[ETranslation.PAYMENT_STATUS_PAID]: "Paid",
	[ETranslation.PAYMENT_STATUS_UNPAID]: "Unpaid",
	[ETranslation.PAYMENT_STATUS_NO_PAYMENT_REQUIRED]: "No payment required",
	[ETranslation.INTERPRETATION_ATTRIBUTES]: "Other criteria",
};

const interpretationInfoBoxTranslation = {
	[ETranslation.INTERPRETATION_INFO_CONTACT]: "Contact Information",
	[ETranslation.INTERPRETATION_INFO_RESERVATION_IN_MIN]: "Reservation in Minutes",
	[ETranslation.INTERPRETATION_INFO_INTERPRETER_INVOICE]: "Interpreter's Customer Fee € / min. without VAT",
	[ETranslation.INTERPRETATION_INTERPRETER_PAYMENT]: "Interpreter's Payment",
	[ETranslation.INTERPRETATION_CUSTOMER_PAYMENT]: "Customer's Payment € / min.",
	[ETranslation.INTERPRETATION_INFO_CUSTOMER_INVOICE]: "Customer's Invoice",
	[ETranslation.INTERPRETATION_INTERPRETERS_PAYMENT_TYPE]: "Interpreter's Payment Type",
	[ETranslation.INTERPRETATION_CANDIDATES]: "Offered Interpreters",
	[ETranslation.INTERPRETATION_CHOSEN_ONE]: "Selected Interpreter",
	[ETranslation.INTERPRETATION_INVOICE_DETAILS]: "Invoice Details",
	[ETranslation.INTERPRETATION_INFO_INVOICE]: "Invoicable",
	[ETranslation.INTERPRETATION_RATINGS]: "Ratings",
	[ETranslation.INTERPRETATION_NO_INVOICE]: "Nullify, no invoice",
	[ETranslation.INTERPRETATION_YES_INVOICE]: "Nullify, send invoice",
	[ETranslation.INTERPRETATION_SEARCH]: "Search",
	[ETranslation.INTERPRETATION_LENGTH_FEES_PAYMENTS]: "Length, payment and fees",
};

//TODO nämä ei taida olla enkussa akäytössä
const interpretationDataTranslations = {
	[ETranslation.INTERPRETATION_DATA_TYPE_INSTANT]: "Instant",
	[ETranslation.INTERPRETATION_DATA_TYPE_BOOKING]: "Booking",
	[ETranslation.INTERPRETATION_DATA_TYPE_ON_SITE]: "On-Site",
	[ETranslation.INTERPRETATION_DATA_TYPE_MESSAGE]: "Message",
	[ETranslation.INTERPRETATION_DATA_TYPE_ASSIGNMENT]: "Assignnment",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_TEACHER]: "Vaka-opettaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SPECIAL_TEACHER]: "Vaka-erityisopettaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_NURSE]: "Vaka-hoitaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_ASSISTANT]: "Vaka-avustaja",
	[ETranslation.INTERPRETATION_DATA_TYPE_DAYCARE_SOCIAL_WORKER]: "Vaka-sosionomi",
	[ETranslation.INTERPRETATION_DATA_TYPE_SUBSTITUTE_ON_SITE]: "Substitute",
	[ETranslation.INTERPRETATION_DATA_TYPE_SHIFT_ON_SITE]: "Shift",
	[ETranslation.INTERPRETATION_DATA_TAGS_VIDEO]: "Video connection",
	[ETranslation.INTERPRETATION_DATA_GENDER_MALE]: "Male",
	[ETranslation.INTERPRETATION_DATA_GENDER_FEMALE]: "Female",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_CUSTOMER]:
		"I want the interpreter to call my provided phone number.",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_TO_INTERPRETER]:
		"I want to call the interpreter myself by phone.",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE_TWILIO]: "Video conference in the app",
	[ETranslation.INTERPRETATION_DATA_CONNECTION_DETAIL_VIDEO_CONFERENCE]:
		"Video conference link (e.g., Google Meet, Zoom, Microsoft Teams)",
	[ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING_SELECT]: "Confirmed booking",
	[ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING_SELECT]: "Preliminary booking",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_SELECT]: "Waiting",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_SELECT]:
		"Job offer for booking interpretation (interpreters can choose after saving)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS_SELECT]:
		"Waiting for reviews (interpreter and customer can review later)",
	[ETranslation.INTERPRETATION_DATA_STATUS_COMPLETE_SELECT]: "Complete (no review option)",
	[ETranslation.INTERPRETATION_DATA_STATUS_CONFIRMED_BOOKING]: "Confirmed booking",
	[ETranslation.INTERPRETATION_DATA_STATUS_PRELIMINARY_BOOKING]: "Preliminary booking",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING]: "Waiting",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING]: "Processing",
	[ETranslation.INTERPRETATION_DATA_STATUS_OFFER_BOOKING_WAITING]: "Waiting for confirmation",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEWS]: "Waiting for reviews",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER_REVIEW]: "Waiting for interpreter review",
	[ETranslation.INTERPRETATION_STATUS_WAITING_CUSTOMER_APPROVAL]: "Waiting for customer approval",
	[ETranslation.INTERPRETATION_DATA_STATUS_DUPLICATE]: "Duplicate",
	[ETranslation.INTERPRETATION_REMOVE_OPEN_OFFERS]: "Remove all open",
	[ETranslation.INTERPRETATION_DATA_STATUS_IN_PROGRESS]: "In progress",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW]: "Waiting for review",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER_REVIEW]: "Waiting for customer review",
	[ETranslation.INTERPRETATION_DATA_STATUS_CUSTOMER_CANCEL]: "Canceled (by customer)",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETERS_NOT_FOUND]: "Interpreters not found",
	[ETranslation.INTERPRETATION_DATA_STATUS_FAVORITES_NOT_FOUND]: "Favorites not found",
	[ETranslation.INTERPRETATION_DATA_STATUS_INTERPRETER_CANCEL]: "Canceled (by interpreter)",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_INTERPRETER]: "Waiting for interpreter review",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_CUSTOMER]: "Waiting for customer review",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW_SINGLE]: "Review pending",
	[ETranslation.INTERPRETATION_DATA_STATUS_WAITING_REVIEW_MULTIPLE]: "Reviews pending",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_15]: "less than 15 minutes",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_30]: "less than 30 minutes",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_45]: "less than 45 minutes",
	[ETranslation.INTERPRETATION_DATA_CUSTOMER_ESTIMATE_MT_45]: "more than 45 minutes",
	[ETranslation.INTERPRETATION_ORDER_BY]: "Order by",
	[ETranslation.INTERPRETATION_ORDER_BY_CREATED]: "Order by date created",
	[ETranslation.INTERPRETATION_ORDER_BY_START_DATE]: "Order by start date",
	[ETranslation.BOOKING_CONFIRMATION]: "Booking confirmation",
	[ETranslation.FEEDBACK_SURVEY]: "Feedback survey",
	[ETranslation.MODIFY_FORM]: "Modify form",
	[ETranslation.INTERPRETERS_OFFERED]: "Offered workers",
	[ETranslation.INTERPRETATION_CANCELED_CUSTOMER]: "Cancelled (customer)",
	[ETranslation.INTERPRETATION_CANCELED_WORKER]: "Cancelled (worker)",
	[ETranslation.INTERPRETATION_BOOKING_ENDED]: "Booking ended",
	[ETranslation.INTERPRETATION_BOOKING_STARTED]: "Booking started",
	[ETranslation.INTERPRETATION_CUSTOMER_PAYMENT_TYPE]: "Customer payment method",
	[ETranslation.INTERPRETATTION_RECLAMATION]: "Reclamation",
	[ETranslation.INTERPRETATION_GENDER_IN_ORDER]: "Gender (In order)",
	[ETranslation.INTERPRETATION_PRICE_WITOUTH_VAT_PER_MIN]: "0VAT price per min",
	[ETranslation.INTREPRETATION_FEEDBACK_SURVEY]: "Feedback survey",
	[ETranslation.INTREPRETATION_FEEDBACK_CONFIRM]: "Are you sure you want to send the feedback survey?",
	[ETranslation.INTREPRETATION_FEEDBACK_RECIPENTS]:
		"If you want to, you can give multiple emails separated by commas.",
	[ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_WORKER]: "Send feedback survey to worker",
	[ETranslation.INTREPRETATION_FEEDBACK_SEND_TO_CUSTOMER]: "Send feedback survey to customer",
	[ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CUSTOMER]: "The worker calls",
	[ETranslation.CONNECTION_TYPE_CUSTOMER_CALL_TO_INTERPRETER]: "The customer calls",
	[ETranslation.CONNECTION_TYPE_CONTACT_CALL_TO_INTERPRETER]: "The contact I enter calls the worker",
	[ETranslation.CONNECTION_TYPE_INTERPRETER_CALL_TO_CONTACT]: "The worker calls to the contact I enter",
	[ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE]: "Video conference link (e.g. Google meet, Zoom)",
	[ETranslation.CONNECTION_TYPE_VIDEO_CONFERENCE_TWILIO]: "Video Conference in app",
	[ETranslation.INTREPRETATION_CHANGE_CONNECTION_TYPE]: "Change contact type",
	[ETranslation.INTREPRETATION_CONNECTION_TYPE]: "Connection Type",
	[ETranslation.INTREPRETATION_CONNECTION_SENT_VIA]: "The information is sent to the contact via",
	[ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_CONTACT]: "Notes will be delivered to the contact",
	[ETranslation.INTREPRETATION_CONNECTION_NOTES_TO_WORKER]: "Notes will be delivered to the worker",
	[ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_CONTACT]: "Reference will be delivered to the contact",
	[ETranslation.INTREPRETATION_CONNECTION_REFERENCE_TO_WORKER]: "Reference will be delivered to the worker",
	[ETranslation.INTREPRETATION_CONNECTION_FIRSTNAME]: "Contacts firstname",
	[ETranslation.INTREPRETATION_CONNECTION_LASTNAME]: "Contacts lastname",
	[ETranslation.INTREPRETATION_CONNECTION_ORGANIZATION]: "Contacts company/organization name",
	[ETranslation.INTREPRETATION_CONNECTION_PHONE]: "Contacts phonenumber",
	[ETranslation.INTREPRETATION_CONNECTION_EMAIL]: "Contacts email",
	[ETranslation.INTREPRETATION_CONNECTION_LANGUAGE]: "Contacts language",
	[ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_MAIL]: "Via email",
	[ETranslation.INTREPRETATION_CONNECTION_INFO_VIA_PHONE]: "Via sms (2 € / order)",
	[ETranslation.INTREPRETATION_WORK_STARTED_AT]: "Workorder start",
	[ETranslation.INTREPRETATION_WORK_ENDED_AT]: "Workorder end",
};

const interpretationProcessingTranslations = {
	[ETranslation.INTERPRETATION_PROCESSING_IN_A_MOMENT]: " in a moment.",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETER_WILL_CALL]: "Be ready. The interpreter will call the number ",
	[ETranslation.INTERPRETATION_PROCESSING_CALL_INTERPRETER]:
		"You can call the interpreter. The interpreter is waiting for your call. Call to number ",
	[ETranslation.INTERPRETATION_PROCESSING_WAITING_REVIEW]: "Waiting for review",
	[ETranslation.INTERPRETATION_PROCESSING_INTERPRETERS_NOT_FOUND]: "Interpreters not found",
	[ETranslation.INTERPRETATION_PROCESSING_FAVORITES_NOT_FOUND]: "Favorites not found",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_VIDEO_CONNECTION]: "Open videoconnection",
	[ETranslation.INTERPRETATION_PROCESSING_OPEN_INTERPRETATION]: "Open interpretation and close interpretation",
	[ETranslation.INTERPRETATION_PROCESSING_CLOSE]: "Close interpretation",
	[ETranslation.INTERPRETATION_PROCESSING_INTERRUPT]: "Interrupt",
	[ETranslation.INTERPRETATION_PROCESSING_TITLE]: "Order processing",
};

const interpretationReviewTranslations = {
	[ETranslation.INTERPRETATION_RATING]: "Rate the translation",
	[ETranslation.INTERPRETATION_REVIEW_TEXT]: "You can give a short review of the translation",
	[ETranslation.INTERPRETATION_START_REVIEW]: "Interpretation is completed",
	[ETranslation.INTERPRETATION_REVIEW_ADD_FAVORITE_INTERPRETER]: "Add to favorite interpreters",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER]: "Interpreter ",
	[ETranslation.INTERPRETATION_REVIEW_INTERPRETER_IS_FAVORITE]: "Interpreter is in favorites",
	[ETranslation.INTERPRETATION_RESPONSE_TIME]: "Response time",
};

const interpretationsTranslations = {
	[ETranslation.INTERPRETATIONS_NOT_FOUND]: "No interpretations found",
	[ETranslation.INTERPRETATIONS_CLEAR_ALL]: "Empty interpretations",
	[ETranslation.INTERPRETATION_PRICE]: "Price (€ includes vat 0%)",
	[ETranslation.INTERPRETATION_RESPONSE_TIME]: "Response time",
};

const videoTranslations = {
	[ETranslation.VIDEO_TOKEN_EXPIRED]: "Meeting cannot be opened. Check the date and time of the meeting",
};

const infoTextTranslations = {
	[ETranslation.INFO_TEXT_BOOKING]:
		"Make a remote interpreter booking by filling in the details and selecting Make Booking.",
	[ETranslation.INFO_TEXT_ON_SITE]: "Select the on-site tab if you want the interpreter to be on-site.",
	[ETranslation.INFO_TEXT_INSTANT]:
		"Select the Instant Interpretation tab at the top of the screen if you need immediate contact with the interpreter.",
};

const modalTranslations = {
	[ETranslation.MODAL_ORDER_CONFIRMED_TITLE]: "Order received",
	[ETranslation.MODAL_CONFIRMATION_TITLE]: "Confirmation",
	[ETranslation.MODAL_CONFIRMATION_INTERRUPT]: "Are you sure you want to interrupt the interpretation?",
	[ETranslation.MODAL_ORDER_CONFIRMED]: "Thank you for your booking",
	[ETranslation.MODAL_ORDER_RECEIVED]: "The booking has been received.",
	[ETranslation.MODAL_ORDER_TRACK]:
		"You can track the information by opening the Upcoming Bookings view from the menu.",
	[ETranslation.MODAL_ARE_YOU_SURE_CLEAR_INTERPRETATIONS]: "Are you sure you want to clear the interpretations?",
	[ETranslation.MODAL_ARE_YOU_SURE_ORDER]: "Are you sure you want to order ",
	[ETranslation.MODAL_AMOUNT_OF_TRANSLATIONS]: " interpretation(s)?",
	[ETranslation.MODAL_AMOUNT_ONE_TRANSLATION]: " interpretation?",
};

const calendarTranslations = {
	[ETranslation.CALENDAR_MODAL_TITLE]: "Available and reserved interpreters",
	[ETranslation.CALENDAR_NO_SELECTION_MESSAGE]: "Please select one or more users or a language pair.",
	[ETranslation.CALENDAR_NO_USERS_MESSAGE]: "No interpreters found with the selected filters",
};

const cancelationFormTranslations = {
	[ETranslation.CANCEL_FORM_MODIFY_TEXT]: "How would you like to modify your booking?",
};

const commentTranslations = {
	[ETranslation.COMMENTS_ADD_NEW_COMMENT]: "Add new comment",
	[ETranslation.COMMENTS_COMMENTED]: "Commented",
	[ETranslation.COMMENTS_COMMENTER]: "Commenter",
	[ETranslation.COMMENTS_COMMENT_GENERAL]: "Comment",
};

const commissionTranslations = {
	[ETranslation.COMMISSION_GENERAL]: "Prospect",
	[ETranslation.COMMISSIONS_SKILLS]: "Skills",
	[ETranslation.COMMISSIONS_LANGUAGES_DESCRIPTION_TEXT]: "Additional language details",
	[ETranslation.COMMISSIONS_CONTRACTS_DESCRIPTION_TEXT]: "Additional contract details",
	[ETranslation.COMMISSIONS_CV_PARSE]: "CV parse %",
	[ETranslation.COMMISSIONS_CV_PARSE_PROBABILITY]: "Probability % to be recruited: ",
	[ETranslation.COMMISSIONS_CV_PARSE_SKILLS]: "Skills: ",
	[ETranslation.COMMISSIONS_CV_PARSE_EDUCATION]: "Education-related words: ",
	[ETranslation.COMMISSIONS_CV_PARSE_WORKING_EXPERIENCE]: "Work experience-related words: ",
	[ETranslation.COMMISSIONS_CV_PARSE_HEALTHCARE]: "Healthcare-related words: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LEGAL]: "Legal words: ",
	[ETranslation.COMMISSIONS_CV_PARSE_CHILDCARE]: "Childcare-related words: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LOCATION]: "Location: ",
	[ETranslation.COMMISSIONS_CV_PARSE_YEARS_OF_EXPERIENCE]: "Estimated years of work experience: ",
	[ETranslation.COMMISSIONS_CV_PARSE_LANGUAGES]: "Languages: ",
	[ETranslation.COMMISSIONS_TEST_CV]: "Test CV",
	[ETranslation.COMMISSIONS_DEVICE]: "Device",
	[ETranslation.COMMISSIONS_IOS]: "iOS",
	[ETranslation.COMMISSIONS_ANDROID]: "Android",
	[ETranslation.COMMISSIONS_DEVICE_DETAILS]: "Device details",
	[ETranslation.COMMISSIONS_SKILL_LEVEL]: "Skill level",
	[ETranslation.COMMISSIONS_EVERYDAY]: "General interpreter",
	[ETranslation.COMMISSIONS_PROFESSIONAL]: "Professional interpreter",
	[ETranslation.COMMISSIONS_EDUCATION_EXPERIENCE]: "Education description",
	[ETranslation.COMMISSIONS_WORK_EXPERIENCE]: "Work Experience description",
	[ETranslation.COMMISSIONS_INTERVIEW_DATE]: "Interview date",
	[ETranslation.COMMISSIONS_INTERVIEW_DETAILS]: "Interview details",
	[ETranslation.COMMISSIONS_ADDED_AS_INTERPRETER]: "Added as interpreter",
	[ETranslation.COMMISSIONS_STATUS_OF_SECURITY_CHECK]: "Security check status",
	[ETranslation.COMMISSIONS_NO_UP_TO_DATE]: "Not up to date",
	[ETranslation.COMMISSIONS_SECURITY_CHECK_DETAILS]: "Security check details",
	[ETranslation.COMMISSIONS_SECURITY_CHECK_DATE]: "Date of security check",
	[ETranslation.COMMISSIONS_CONTRACT_SEND]: "Contract sent",
	[ETranslation.COMMISSIONS_CONTRACT_OPEN]: "Contract active",
	[ETranslation.COMMISSIONS_CONTRACT_COMPLETED]: "Contract completed",
	[ETranslation.COMMISSIONS_CV]: "CV",
	[ETranslation.COMMISSIONS_EDUCATION_ATTACHMENTS]: "Education attachments",
	[ETranslation.COMMISSIONS_MESSAGE_TO_APPLICANT]:
		"Hello, thank you for your application. You can complete your application and attach the required documents (CV and education certificates) to this applicant profile.",
	[ETranslation.COMMISSIONS_VERIFYING_INFO]:
		"By saving this applicant profile, I confirm that the information I provided is accurate.",
};

const summaryTranslations = {
	[ETranslation.SUMMARIES_TIME_INTERVAL]: "Time interval",
	[ETranslation.SUMMARIES_GRADE]: "Grade",
	[ETranslation.SUMMARY_YOUPRET_NAME]: "Youpret Oy",
	[ETranslation.SUMMARY_YOUPRET_ADDRESS]: "Torikatu 29 A",
	[ETranslation.SUMMARY_YOUPRET_CITY]: "80100 Joensuu",
	[ETranslation.SUMMARY_YOUPRET_INFO]: "Y-tunnus 2761915-3",
	[ETranslation.SUMMARY_OF_SALES]: "Youpret sales summary",
	[ETranslation.SUMMARY_TOTAL_COMPENSATION]: "Total compensation",
	[ETranslation.SUMMARY_COMPENSATION_TO_ACCOUNT]:
		"The compensation for sales will be transferred to the following account",
	[ETranslation.SUMMARY_PAYMENT_DATE]: "Payment date",
	[ETranslation.SUMMARY_NET_AMOUNT_AFTER_DEDUCTIONS]:
		"The amount to be transferred to the account is subject to deductions as per the contract.",
	[ETranslation.SUMMARY_GRADING_AVERAGE]: "Summary of Customer Feedback for the period",
	[ETranslation.SUMMARY_GRADING_POINTS]:
		"The average rating given by customers for your interpretations on a scale of 1-5 was:",
	[ETranslation.SUMMARY_QUESTIONS]:
		"If you have any questions or would like to provide feedback and suggestions for improvement, you can reach us at ",
	[ETranslation.SUMMARY_EMAIL]: "office@youpret.com",
	[ETranslation.COMMON_MESSAGE]: "Message",
	[ETranslation.SUMMARY_CONFIRM_ACTION]: "Confirm action",
	[ETranslation.SUMMARY_PUBLISHED]: "Publish",
	[ETranslation.SUMMARY_DRAFT]: "Change to draft",
};

const tagTranslations = {
	[ETranslation.TAGS_LANGUAGE_VERSION]: "Language version",
};

const reservedDateTranslations = {
	[ETranslation.RESERVED_DATE_NO_RESULTS]: "No reserved dates found.",
	[ETranslation.RESERVED_DATE_USER_NAME]: "Username",
	[ETranslation.RESERVED_DATE_DATE]: "Timeframe",
	[ETranslation.RESERVED_DATE_DESCRIPTION]: "Description",
	[ETranslation.RESERVED_DATE_TYPE]: "Reservation type",
	[ETranslation.RESERVED_DATE_OPTIONS]: "Days",
};

const orderTranslations = {
	[ETranslation.ORDERS_ORGANIZATION]: "Organization name",
	[ETranslation.ORDERS_LANGUAGE_CODE]: "Language code",
};

const tasksTranslations = {
	[ETranslation.TASKS_ORDER_TASK]: "Order access requests",
	[ETranslation.TASKS_ORGANIZATION]: "Organization / unit",
	[ETranslation.TASKS_COMMISSIONER]: "User access requests",
	[ETranslation.TASKS_ORDERED_TASKITEMS]: "Orderer",
	[ETranslation.TASKS_NOTES]: "Additional information / instructions / feedback to Youpret office",
	[ETranslation.TASKS_ADD_USER]: "Add new user",
};

const feesTranslations = {
	[ETranslation.PAYMENT]: "Customer fee",
	[ETranslation.PAYMENT_DATE]: "Payment date",
	[ETranslation.PAYMENT_ROW]: "Add row",
	[ETranslation.PAYMENT_INSTANT_ACCEPTANCE_FEE]: "Instant acceptance fee",
	[ETranslation.PAYMENT_ADDITIONAL_FEE]: "Additional fee",
	[ETranslation.PAYMENT_FEE_WITHOUT_VAT]: "Price without VAT",
	[ETranslation.PAYMENT_AMOUNT]: "Amount",
	[ETranslation.PAYMENT_UNIT]: "Unit",
	[ETranslation.PAYMENT_MINUTES]: "minutes",
	[ETranslation.PAYMENT_CURRENCY]: "Currency",
	[ETranslation.PAYMENT_EUR]: "EUR",
	[ETranslation.PAYMENT_USD]: "USD",
	[ETranslation.PAYMENT_DESCRIPTION]: "Description",
	[ETranslation.PAYMENT_EEZY]: "Eezy",
	[ETranslation.PAYMENT_TRUSTER]: "Truster",
	[ETranslation.PAYMENT_MANUAL]: "Manual",
	[ETranslation.PAYMENT_NO_PAYMENT]: "No payment",
};

const pagesLanguageCodesTranslations = {
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_TITLE]: "Language code",
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_INFO]: "",
	[ETranslation.PAGES_LANGUAGE_CODE_EDIT_DELETE_CONFIRM]: "Are you sure you want to delete language code?",
	[ETranslation.PAGES_LANGUAGE_CODES_LIST_TITLE]: "Language codes",
	[ETranslation.PAGES_LANGUAGE_CODES_LIST_ADD_NEW]: "Add language code",
};

const pagesLanguagesTranslations = {
	[ETranslation.PAGES_LANGUAGE_EDIT_TITLE]: "Language",
	[ETranslation.PAGES_LANGUAGE_EDIT_INFO]: "",
	[ETranslation.PAGES_LANGUAGE_EDIT_DELETE_CONFIRM]: "Are you sure you want to delete language?",
	[ETranslation.PAGES_LANGUAGES_LIST_TITLE]: "Languages",
	[ETranslation.PAGES_LANGUAGES_LIST_ADD_NEW]: "Add language",
};

const userGroupTranslations = {
	[ETranslation.USER_GROUPS_LIST_TITLE]: "User groups",
	[ETranslation.USER_GROUPS_LIST_ADD]: "Add user group",
	[ETranslation.USER_GROUPS_LIST_NO_RESULTS]: "No user groups",
};

const userContractSettingsTranslations = {
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_TITLE]: "Contract settings",
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_ADD]: "Add",
	[ETranslation.USER_CONTRACT_SETTINGS_LIST_NO_RESULTS]: "No settings",
	[ETranslation.USER_CONTRACT_SETTINGS_EDIT_TITLE]: "Contract settings",
	[ETranslation.USER_CONTRACT_SETTINGS_EDIT_DELETE]: "Are you sure you want to delete settings?",
	[ETranslation.USER_CONTRACT_SETTINGS_TYPE_PERMANENT]: "Permanent",
	[ETranslation.USER_CONTRACT_SETTINGS_TYPE_TEMPORARY]: "Temporary",
	[ETranslation.USER_CONTRACT_HOLIDAY_ACCURUAL_ONE_YEAR_TWO_AFTER_TWO_AND_HALF]: "1.4-31.3, 2/2,5 days",
};

const eezyTrusterTranslations = {
	[ETranslation.EEZY_TRUSTER_EVENT]: "Eezy/Truster event",
	[ETranslation.EEZY_TRUSTER_SUMMARY_OF_JOBS]: "Summary of jobs in payment",
	[ETranslation.EEZY_TRUSTER_INFORMATION]: "Eezy/Truster information",
	[ETranslation.COMMON_PRODUCT_NAME]: "Product name",
	[ETranslation.COMMON_MINUTES_IN_TOTAL]: "Minutes in total",
	[ETranslation.COMMON_PRICE_OF_ONE]: "Unit price",
	[ETranslation.COMMON_PAYMENT_AMMOUNT]: "Ammount of payment",
	[ETranslation.COMMON_SEND_ALL]: "Send all",
	[ETranslation.EEZY_TRUSTER_STATUS_NO_EVENT]: "No events",
	[ETranslation.EEZY_TRUSTER_STATUS_READY_TO_SEND]: "Ready to send",
	[ETranslation.EEZY_TRUSTER_STATUS_SENDING]: "Sending",
	[ETranslation.EEZY_TRUSTER_STATUS_SENT]: "Sent",
	[ETranslation.EEZY_TRUSTER_STATUS_FAILED]: "Failed",
	[ETranslation.EEZY_TRUSTER_STATUS_FAILED_VALIDATION]: "Failed validation",
	[ETranslation.USER_RESET_EEZY_TRUSTER_ID]: "Reset Eezy/Truster ID",
};

const translationsEn = {
	...commonTranslations,
	...menuTranslations,
	...Base64DropzoneTranslations,
	...customDropZoneTranslations,
	...deleteModalTranslations,
	...infoContactRequestTranslations,
	...messageTranslations,
	...newsItemTranslations,
	...organizationGroupTranslations,
	...priceSeasonsTranslations,
	...siteTranslations,
	...textMessageTranslations,
	...uiTranslations,
	...pagesFileUploadTranslations,
	...pagesContactRequestTranslations,
	...pagesMessagesTranslations,
	...pagesNewsTranslations,
	...pagesOrganizationsTranslations,
	...organizationCompaniesTranslations,
	...pagesPriceSeasonsTranslations,
	...pagesPriceRulesTranslations,
	...pagesSiteTranslations,
	...pagesTextMessagesTranslations,
	...dataContactRequestTranslations,
	...dataPriceSeasonsTranslations,
	...pagesCustomerReportsTranslations,
	...storeTranslations,
	...appTranslations,
	...logItemTranslations,
	...interpretationTranslations,
	...interpretationProcessingTranslations,
	...videoTranslations,
	...interpretationReviewTranslations,
	...interpretationsTranslations,
	...calendarTranslations,
	...cancelationFormTranslations,
	...usersTranslations,
	...commentTranslations,
	...commissionTranslations,
	...summaryTranslations,
	...tagTranslations,
	...orderTranslations,
	...tasksTranslations,
	...reservedDateTranslations,
	...interpretationDataTranslations,
	...interpretationInfoBoxTranslation,
	...feesTranslations,
	...infoTextTranslations,
	...modalTranslations,
	...pagesLanguageCodesTranslations,
	...pagesLanguagesTranslations,
	...userGroupTranslations,
	...userContractSettingsTranslations,
	...eezyTrusterTranslations,
	...attributeTranslations,
	...salaryTranslations,
	...categoryTranslations,
};

export default translationsEn;

import { useState } from 'react';

import { EInputType, IInputField } from '../ui/Input/Input';
import { JOB_APPLICATION_STATUS_OPTIONS } from '../../shared/job-application-data';


export enum EJobApplicationAdminInputs {
	status = "status",
	userCreatedDate = "userCreatedDate",
	interviewDate = "interviewDate",
	interviewText = "interviewText",
	notesText = "notesText",
}

export const useJobApplicationAdminInputs = () => {
	const [adminInputs, setAdminInputs] = useState<IInputField>({
		[EJobApplicationAdminInputs.status]: {
			type: EInputType.radio,
			label: "Tila",
			value: "",
			options: JOB_APPLICATION_STATUS_OPTIONS,
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.interviewDate]: {
			type: EInputType.date,
			label: "Haastattelun pvm",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.interviewText]: {
			type: EInputType.textarea,
			label: "Haastattelun lisätiedot",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.notesText]: {
			type: EInputType.textarea,
			label: "Muistiinpanot",
			value: "",
			validation: {
				required: true,
			},
		},
		[EJobApplicationAdminInputs.userCreatedDate]: {
			type: EInputType.date,
			label: "Käyttäjätunnus luotu pvm",
			value: "",
			validation: {
				required: true,
			},
		},
	});
	return { adminInputs, setAdminInputs };
}